import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  MediaCommentEnum,
  useCreateMediaCommentMutation,
  useGetMediaCommentsByMediaIdQuery,
} from "@api/graphql/types-and-hooks";
import { EMOJI_BUTTONS } from "@pages/VideoSessions/const";
import { EmojiButtonInterface, EmojiMarker } from "@pages/VideoSessions/types";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface UseEmojiMarkersProps {
  mediaId: string;
  videoDuration: number;
}

export const useEmojiMarkers = ({ mediaId, videoDuration }: UseEmojiMarkersProps) => {
  const [emojiMarkers, setEmojiMarkers] = useState<EmojiMarker[]>([]);
  const [selectedEmoji, setSelectedEmoji] = useState<EmojiButtonInterface | null>(null);
  const createMediaComment = useCreateMediaCommentMutation();
  const { mediaId: routeMediaID } = useParams();
  const { commentsByMediaIdQuery } = useVideoSessionData();

  const { data: commentsData, isLoading: isLoadingComments } = useGetMediaCommentsByMediaIdQuery(
    { mediaId },
    { enabled: Boolean(mediaId) },
  );

  useEffect(() => {
    if (!commentsData?.getMediaComments?.edges) return;

    const markers = commentsData.getMediaComments.edges
      .map((comment) => {
        const matchingButton = EMOJI_BUTTONS.find((btn) => btn.value === comment?.node?.commentType);
        if (!matchingButton || !comment?.node?.id) return null;

        const commentTime = comment?.node?.mediaStartTime || 0;
        const position = videoDuration > 0 ? (commentTime / videoDuration) * 100 : commentTime;
        return {
          emoji: matchingButton.emoji,
          position,
          time: commentTime,
          commentId: comment.node.id,
          content: comment.node.content,
          authoId: comment.node.author?.auth0id,
          authorFirstName: comment.node.author?.provider?.providermetadata?.firstName ?? "",
          authorLastName: comment.node.author?.provider?.providermetadata?.lastName ?? "",
        };
      })
      .filter((marker): marker is EmojiMarker => marker !== null);

    setEmojiMarkers(markers);
  }, [commentsData, videoDuration]);

  const handleCommentSubmit = async (content: string, timestamp: number) => {
    if (!videoDuration || !selectedEmoji) return;

    const percentage = (timestamp / videoDuration) * 100;

    try {
      const response = await createMediaComment.mutateAsync({
        input: {
          content,
          commentType: selectedEmoji.value as unknown as MediaCommentEnum,
          mediaId,
          mediaStartTime: timestamp,
        },
      });

      const commentId = response.createMediaComment?.mediaComment?.id;
      if (commentId) {
        setEmojiMarkers((prev) => [
          ...prev,
          {
            emoji: selectedEmoji.emoji,
            position: percentage,
            time: timestamp,
            commentId,
            content,
            authoId: response.createMediaComment?.mediaComment?.author?.auth0id || "",
            authorFirstName:
              response.createMediaComment?.mediaComment?.author?.provider?.providermetadata?.firstName || "",
            authorLastName:
              response.createMediaComment?.mediaComment?.author?.provider?.providermetadata?.lastName || "",
          },
        ]);
        if (routeMediaID) {
          commentsByMediaIdQuery?.refetch();
        }
      }
    } catch (error) {
      console.error("Failed to create comment:", error);
    }

    setSelectedEmoji(null);
  };

  return {
    emojiMarkers,
    isLoadingComments,
    handleEmojiClick: (emoji: EmojiButtonInterface) => setSelectedEmoji(emoji),
    selectedEmoji,
    handleCommentSubmit,
    closeCommentBox: () => setSelectedEmoji(null),
  };
};
