import React from "react";

import { Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import { UserInitialsView } from "@components/UserInitial/UserInitialsView";
import { SessionRating } from "@pages/VideoSessions/components/Utils/SessionRating";

interface HeaderContentProps {
  learnerFullName: string;
  providerFirstName?: string;
  providerLastName?: string;
  date: string;
  sessionRating?: number;
}

export const HeaderContent: React.FC<HeaderContentProps> = ({
  learnerFullName,
  providerFirstName,
  providerLastName,
  date,
  sessionRating,
}) => {
  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:items-baseline gap-1 sm:gap-4">
        <Heading type="h2">{learnerFullName}</Heading>
        <Paragraph displayType="normal">{date}</Paragraph>
      </div>
      <div className="flex flex-wrap gap-2">
        <Paragraph colorType="secondary" className="text-sm">
          Attending Provider:{" "}
        </Paragraph>
        <UserInitialsView firstName={providerFirstName || ""} lastName={providerLastName || ""} />
        {sessionRating && <SessionRating rating={sessionRating} />}
      </div>
    </div>
  );
};
