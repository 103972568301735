import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import {
  ApiAssessmentAiLtgPredictionStatusChoices,
  ApiAssessmentAiReport1PredictionStatusChoices,
  ApiAssessmentAiReport2PredictionStatusChoices,
  ApiAssessmentAiStgPredictionStatusChoices,
  ApiAssessmentAiTargetPredictionStatusChoices,
  ApiAssessmentAssessmentStatusChoices,
  AssessmentType,
  useFilesForAssessmentQuery,
  useGetAssessmentsQuery,
  useGetUserAccountDetailsQuery,
} from "@api/graphql/types-and-hooks";
import {
  AssessmentReport,
  AssessmentReportsTable,
  StatusType,
} from "@components/AssessmentReportsTable/AssessmentReportsTable";
import { NewAssessmentDialog } from "@components/NewAssessmentDialog/NewAssessmentDialog";
import { WalkthroughDialog } from "@components/WalkthroughDialog/WalkthroughDialog";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import "@components/notifications/notifications.css";
import { useAdminData } from "@providers/AdminDataProvider";
import { CreditHeader } from "@routes/AssessmentReports/CreditHeader";

const getNumberOfSectionsDone = (report?: AssessmentType): number => {
  let numberOfSectionsDone = 0;

  if (report?.assessmentClientDetails) {
    numberOfSectionsDone += 1;
  }

  if (
    report?.aiReport1PredictionStatus ===
    ApiAssessmentAiReport1PredictionStatusChoices["ReportFieldPredictionPredicted"]
  ) {
    numberOfSectionsDone += 1;
  }

  if (report?.aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices["GoalPredictionPredicted"]) {
    numberOfSectionsDone += 1;
  }

  if (
    report?.aiStgPredictionStatus === ApiAssessmentAiStgPredictionStatusChoices["GoalPredictionPredicted"] &&
    report?.aiTargetPredictionStatus === ApiAssessmentAiTargetPredictionStatusChoices["GoalPredictionPredicted"]
  ) {
    numberOfSectionsDone += 1;
  }

  if (
    report?.aiReport2PredictionStatus ===
    ApiAssessmentAiReport2PredictionStatusChoices["ReportFieldPredictionPredicted"]
  ) {
    numberOfSectionsDone += 1;
  }

  return numberOfSectionsDone;
};

const getStatusFromReport = (report?: AssessmentType): StatusType => {
  const assessmentStatus = report?.assessmentStatus;
  const aiFieldsPart1PredictionStatus = report?.aiReport1PredictionStatus;
  const aiFieldsPart2PredictionStatus = report?.aiReport2PredictionStatus;
  const aiLtgPredictionStatus = report?.aiLtgPredictionStatus;
  const aiStgPredictionStatus = report?.aiStgPredictionStatus;
  const aiTargetPredictionStatus = report?.aiTargetPredictionStatus;

  if (aiFieldsPart1PredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices["ReportFieldPredictionPending"]) {
    return "Report Page 1 Generating";
  }
  if (aiFieldsPart1PredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices["Failure"]) {
    return "Report Page 1 Failed";
  }
  if (aiFieldsPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices["ReportFieldPredictionPending"]) {
    return "Report Page 2 Generating";
  }

  if (aiFieldsPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices["Failure"]) {
    return "Report Page 2 Failed";
  }

  if (aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices["GoalPredictionPending"]) {
    return "Long Term Goals Generating";
  }
  if (aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices["Failure"]) {
    return "Long Term Goals Failed";
  }

  if (aiStgPredictionStatus === ApiAssessmentAiStgPredictionStatusChoices["GoalPredictionPending"]) {
    return "Short Term Goals Generating";
  }
  if (aiStgPredictionStatus === ApiAssessmentAiStgPredictionStatusChoices["Failure"]) {
    return "Short Term Goals Failed";
  }

  if (aiTargetPredictionStatus === ApiAssessmentAiTargetPredictionStatusChoices["GoalPredictionPending"]) {
    return "Targets Generating";
  }

  if (aiTargetPredictionStatus === ApiAssessmentAiTargetPredictionStatusChoices["Failure"]) {
    return "Targets Failed";
  }

  if (assessmentStatus === ApiAssessmentAssessmentStatusChoices["Draft"]) {
    return "Draft";
  }

  if (assessmentStatus === ApiAssessmentAssessmentStatusChoices["Completed"]) {
    return "Published";
  }
  return "Draft";
};

const maxFiles = 200;

export const AssessmentReports: React.FC = () => {
  const go = useNavigate();
  const { pathname } = useLocation();
  const { assessmentCreditsRemaining, isSelfServeUser } = useAdminData();

  const [tableData, setTableData] = useState<AssessmentReport[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { assessmentId } = useParams();
  const { isLoading, isError, error, data: filesData } = useFilesForAssessmentQuery({ assessmentId });

  useEffect(() => {
    if (assessmentId && !isLoading && !isError && filesData) {
      const currentAssessment = filesData?.getAssessments?.edges[0]?.node;
      const numberOfFiles = currentAssessment?.files?.edges?.length || 0;
      if (numberOfFiles > maxFiles) {
        notifyError(`You have reached the maximum number of files of ${maxFiles} allowed for this assessment`);
      }
    } else if (isError) {
      console.error("Error fetching files for assessment", error);
    }
  }, [isLoading]);

  const accountDetails = useGetUserAccountDetailsQuery(
    {},
    {
      queryKey: ["get-account-details", isSelfServeUser],
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      staleTime: Infinity,
      enabled: isSelfServeUser,
    },
  );

  const { refetch: accountRefetch } = accountDetails;

  const getAssessmentsQuery = useGetAssessmentsQuery(
    { records: 100 },
    {
      queryKey: ["get-assessments"],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
    },
  );

  const previousAbPath = useRef<string>("");

  useEffect(() => {
    const abPath = pathname.split("/assessment-reports")?.pop() || "";
    if (abPath === "" && previousAbPath.current.length) {
      refetch();
      if (isSelfServeUser) {
        accountRefetch();
      }
    }
    previousAbPath.current = abPath;
  }, [pathname]);

  const { data, refetch, isFetching } = getAssessmentsQuery;

  useEffect(() => {
    if (data?.getAssessments?.edges) {
      const edges = data.getAssessments.edges;
      const mappedData = edges
        .map((edge) => {
          const node = edge?.node;
          if (!node) {
            return null;
          }
          const clientFirstName = node?.assessmentClientDetails?.clientFirstName || "N/A";
          const clientLastName = node?.assessmentClientDetails?.clientLastName || "";
          const clientName = `${clientFirstName} ${clientLastName}`;
          const status = getStatusFromReport(node as AssessmentType);
          const pagesDone = getNumberOfSectionsDone(node as AssessmentType);

          const report: AssessmentReport = {
            id: node?.id,
            clientName,
            learnerId: node?.learner?.id,
            dateCreated: new Date(node?.createdDate),
            lastUpdated: new Date(node?.lastUpdatedDate),
            status,
            pagesDone,
          };

          return report;
        })
        .filter(Boolean) as AssessmentReport[];

      setTableData(mappedData); // Set the table data
    }
  }, [data]);

  useEffect(() => {
    if (window.location.search.includes("redirect_status=succeeded")) {
      notifySuccess("Payment successful");
    } else if (window.location.search.includes("redirect_status=failed")) {
      notifyError("Payment failed");
    }
  }, []);

  return (
    <div className="mx-auto  w-full flex-col content-between items-center lg:flex-col lg:items-start">
      {pathname === "/assessment-reports" ? (
        <CreditHeader
          isSelfServeUser={isSelfServeUser}
          setDialogOpen={setDialogOpen}
          credits={assessmentCreditsRemaining}
          refetch={refetch}
        />
      ) : null}

      {pathname === "/assessment-reports" ? (
        <AssessmentReportsTable
          reports={tableData}
          refetch={getAssessmentsQuery.refetch}
          loading={isFetching}
          onRowClick={(report) =>
            go(`${report.id}`, {
              state: { ...report },
            })
          }
        />
      ) : (
        <Outlet />
      )}
      <NewAssessmentDialog
        isSelfServeUser={isSelfServeUser}
        isDialogOpen={isDialogOpen}
        credits={assessmentCreditsRemaining}
        setDialogOpen={(open) => setDialogOpen(open)}
      />

      <WalkthroughDialog />
    </div>
  );
};
