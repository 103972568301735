import { formatPlayerTime } from "@utils/helpers";
import { ReactNode, forwardRef } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { convertReadableString } from "@components/forms/utils";

interface TranscriptLineViewProps {
  transcriptRecordId: string;
  conversation: ReactNode;
  startTime: number;
  endTime: number;
  speaker: string | null;
  onClick: (time: number, id: string) => void;
  isSelected: boolean; // New prop to indicate if this item is selected
}

// Use React.forwardRef to wrap the component and accept a ref
export const TranscriptLineView = forwardRef<HTMLDivElement, TranscriptLineViewProps>(function TranscriptLineView(
  { transcriptRecordId, speaker, startTime, conversation, onClick, isSelected },
  ref,
) {
  return (
    <div
      ref={ref} // Attach the ref to the root div
      className={`px-4 py-2 gap-1 flex flex-col bg-white cursor-pointer border rounded-lg 
      ${isSelected ? "border-limestone-900" : "border-limestone-100"}`}
      onClick={() => onClick(startTime, transcriptRecordId)}
    >
      <div className="flex flex-row gap-2 items-center justify-between">
        <Small displayType="loud">{convertReadableString(speaker || "")}</Small>
        <Small displayType="loud" className="text-interaction-primary">
          {formatPlayerTime(startTime)}
        </Small>
      </div>
      <Paragraph colorType="primary">{conversation}</Paragraph>
    </div>
  );
});
