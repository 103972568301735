// Helper function to format time in 12-hour format with AM/PM
export function formatTime(date: Date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Convert 0 to 12
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${hours}:${formattedMinutes}${ampm}`;
}

// Helper function to format date as M/D/YY
export function formatDate(date: Date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().slice(-2);
  return `${month}/${day}/${year}`;
}

/**
 * Displays a date range in a human-readable format.
 *
 * @param {string} totalDuration - total duration of the session in seconds
 * @param {Date} startDate - start date of the session
 *
 * @returns {string} - display string in the format "M/D/YY @ h:mma - h:mma"
 */
export function displayDate(totalDuration: number, startDate: Date): string {
  const endDate: Date = new Date(startDate.getTime() + totalDuration * 1000);
  return `${formatDate(startDate)} @ ${formatTime(startDate)} - ${formatTime(endDate)}`;
}
