import { useMemo, useRef, useState } from "react";

import { VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

import { EmojiCommentButtons } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentButtons";
import { EmojiCommentPanel } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentPanel";
import { VideoPlayerContent } from "@pages/VideoSessions/components/VideoPlayerContent";
import { useEmojiMarkers } from "@pages/VideoSessions/hooks/useEmojiMarkers";

interface VideoContainerProps {
  videoUrl: string;
  mediaId: string;
  onMarkerClick: (time: number, commentId: string) => void;
  externalPlayerRef?: React.RefObject<VideoPlayerHandle>; // To handle player in the details view
  commentCount?: string;
  onDurationChange: (duration: number) => void;
}

export const VideoContainer: React.FC<VideoContainerProps> = ({
  videoUrl,
  mediaId,
  onMarkerClick,
  externalPlayerRef,
  commentCount,
  onDurationChange,
}) => {
  const localPlayerRef = useRef<VideoPlayerHandle>(null);
  const playerRef = externalPlayerRef || localPlayerRef;
  const [currentDuration, setCurrentDuration] = useState(0);

  const getCurrentTime = () => {
    return playerRef.current?.player?.currentTime() || 0;
  };

  const handleDurationChange = (duration: number) => {
    setCurrentDuration(duration);
    onDurationChange(duration);
  };

  const { handleEmojiClick, selectedEmoji, handleCommentSubmit, closeCommentBox, emojiMarkers, isLoadingComments } =
    useEmojiMarkers({
      mediaId,
      videoDuration: currentDuration,
    });

  const handleMarkerClick = (timestamp: number, commentId: string) => {
    if (playerRef.current) {
      playerRef.current.seekTo(timestamp);
      if (playerRef.current.player) {
        playerRef.current.player.pause();
      }
    }
    onMarkerClick(timestamp, commentId);
  };

  const MemoizedVideoPlayerContent = useMemo(
    () => <VideoPlayerContent ref={playerRef} videoUrl={videoUrl} onDurationChange={handleDurationChange} />,
    [videoUrl, mediaId],
  );

  return (
    <div className="w-full aspect-video" data-video-player>
      {MemoizedVideoPlayerContent}
      <div className="relative flex flex-col gap-2">
        <EmojiCommentPanel
          emojiMarkers={emojiMarkers}
          isLoading={isLoadingComments || currentDuration <= 0}
          mediaId={mediaId}
          onMarkerClick={handleMarkerClick}
        />
        <EmojiCommentButtons
          onEmojiClick={handleEmojiClick}
          disabled={currentDuration <= 0}
          selectedEmoji={selectedEmoji}
          onCommentSubmit={handleCommentSubmit}
          onClose={closeCommentBox}
          getCurrentTime={getCurrentTime}
          playerRef={playerRef}
          commentCount={commentCount}
        />
      </div>
    </div>
  );
};
