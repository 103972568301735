import { config } from "@config/config";

import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { FileRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/hooks";

interface ClinicalNotesProps {
  existingClinicalNotes: ExistingFileInterface[];
}
export const ClinicalNotes: React.FC<ClinicalNotesProps> = ({ existingClinicalNotes }) => {
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const fileKind: FileTypeEnum = FileTypeEnum.ClinicalNotes;
  return (
    <FileRow
      title="Notes and Supporting Documents"
      subtitle="eg: IEP, 504 Plan, Medical Records, OT report, Clinical Notes, Recording of calls with the teacher"
      isLast
    >
      <FileUploadAndParse
        key={JSON.stringify(existingClinicalNotes)}
        fileKind={fileKind}
        title={"Documents"}
        supportedFileTypes={["pdf", "png", "doc", "docx"]}
        pollInterval={config.REPORT_POLLING_INTERVAL}
        maxSize={"1GB"}
        existingFiles={existingClinicalNotes}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
      />
    </FileRow>
  );
};
