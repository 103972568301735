import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  CalendarDateRange,
  CalendarPicker,
  SearchableDropdown,
  SearchableDropdownItem,
  Select,
  SelectItem,
  Small,
} from "@fronterahealth/frontera-ui-components";

import {
  MediaSortByEnum,
  useGetProvidersForSessionQuery,
  useGetSessionClientsQuery,
} from "@api/graphql/types-and-hooks";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

export const FilterBar: React.FC = () => {
  const { pathname } = useLocation();
  const {
    setFilterSelectedClients,
    setFilterSelectedProviders,
    filterSelectedClients,
    filterSelectedProviders,
    filterSessionDates,
    setFilterSessionDates,
    filterSelectedRating,
    setFilterSelectedRating,
    sortBy,
    setSortBy,
    isUserRBT,
    totalFilteredMediaCount,
  } = useVideoSessionData();
  const [sessionClients, setSessionClients] = useState<SearchableDropdownItem[]>([]);
  const [sessionProviders, setSessionProviders] = useState<SearchableDropdownItem[]>([]);

  const [recentSearchesForClient, setRecentSearchesForClient] = useState<SearchableDropdownItem[]>([]);
  const [recentSearchesForProvider, setRecentSearchesForProvider] = useState<SearchableDropdownItem[]>([]);

  const { data: sessionClientsData, isFetching: isSessionClientsDataLoading } = useGetSessionClientsQuery(
    {},
    {
      queryKey: ["getSessionClients"],
      refetchOnWindowFocus: false,
      retry: false,
      enabled: pathname.includes("/video-sessions"),
    },
  );
  const { data: sessionProvidersData, isFetching: isSessionProvidersDataLoading } = useGetProvidersForSessionQuery(
    {},
    {
      queryKey: ["getProvidersForSession"],
      refetchOnWindowFocus: false,
      retry: false,
      enabled: pathname.includes("/video-sessions") && !isUserRBT,
    },
  );
  useEffect(() => {
    if (sessionClientsData) {
      setSessionClients(
        sessionClientsData?.learnersRecentSession?.edges?.map((item) => ({
          id: item?.node?.sessionLearners.id || "",
          label: `${item?.node?.sessionLearners.learnermetadata?.firstName || ""} ${item?.node?.sessionLearners.learnermetadata?.lastName || ""}`,
        })) || [],
      );
    }
  }, [sessionClientsData]);

  useEffect(() => {
    if (sessionProvidersData) {
      setSessionProviders(
        sessionProvidersData?.getProvidersForSession?.edges?.map((item) => ({
          id: item?.node?.id || "",
          label: `${item?.node?.providermetadata?.firstName || ""} ${item?.node?.providermetadata?.lastName || ""}`,
          category: "RBT",
        })) || [],
      );
    }
  }, [sessionProvidersData]);

  const handleRecentSearchAddForClient = (item: SearchableDropdownItem) => {
    setRecentSearchesForClient((prev) => [...prev, item].slice(-5)); // Keep the last 5 searches
  };

  const handleRecentSearchAddForProvider = (item: SearchableDropdownItem) => {
    setRecentSearchesForProvider((prev) => [...prev, item].slice(-5)); // Keep the last 5 searches
  };

  const handleDateChange = (newDates: CalendarDateRange) => {
    setFilterSessionDates(newDates);
  };

  return (
    <div className="w-full max-md:gap-2 flex flex-row flex-wrap justify-between items-center">
      <div className="gap-2 flex flex-wrap -mx-4 px-4 items-center">
        <SearchableDropdown
          items={sessionClients}
          label="Client"
          placeholder="Search Client"
          onSelectionChange={(items: SearchableDropdownItem[]) => {
            setFilterSelectedClients(items);
          }}
          recentSearches={recentSearchesForClient}
          onRecentSearchAdd={handleRecentSearchAddForClient}
          isLoading={isSessionClientsDataLoading}
          selectedItems={filterSelectedClients}
        />
        {!isUserRBT ? (
          <SearchableDropdown
            items={sessionProviders}
            label="RBT"
            placeholder="Search RBT"
            onSelectionChange={(items: SearchableDropdownItem[]) => {
              setFilterSelectedProviders(items);
            }}
            recentSearches={recentSearchesForProvider}
            onRecentSearchAdd={handleRecentSearchAddForProvider}
            isLoading={isSessionProvidersDataLoading}
            selectedItems={filterSelectedProviders}
          />
        ) : null}
        <CalendarPicker
          placeholder="Session Date"
          selectedDateRange={filterSessionDates}
          inputSize="md"
          onDateChange={handleDateChange}
          mode="filter"
        />
        <Select
          items={[
            { id: "1", primary: "★" },
            { id: "2", primary: "★ ★" },
            { id: "3", primary: "★ ★ ★" },
            { id: "4", primary: "★ ★ ★ ★" },
            { id: "5", primary: "★ ★ ★ ★ ★" },
          ]}
          title=""
          placeholderText="Session Rating"
          setSelected={(item: SelectItem) => {
            setFilterSelectedRating(item);
          }}
          selected={filterSelectedRating}
          className="flex"
          inputContainerClassName="mt-0 w-48"
          actionItem={{
            label: "Clear",
            onClick: () => {
              setFilterSelectedRating(undefined);
            },
          }}
        />
      </div>
      <div className="gap-4 flex flex-row sm:justify-between items-center">
        {/* Removing for now because it's not shows correct count */}
        <Small>{totalFilteredMediaCount} Videos</Small>
        <Select
          items={[
            { id: MediaSortByEnum.DescSessionDate, primary: "Most Recent Session" },
            { id: MediaSortByEnum.DescSessionUploadDate, primary: "Newest Upload" },
            { id: MediaSortByEnum.DescSessionRating, primary: "Highest Session Rating" },
            { id: MediaSortByEnum.AscSessionRating, primary: "Lowest Session Rating" },
          ]}
          title=""
          placeholderText=""
          selected={sortBy}
          setSelected={(item) => {
            setSortBy(item);
          }}
          inputContainerClassName="mt-0 w-56"
          className="flex"
        />
      </div>
    </div>
  );
};
