import { format } from "date-fns";
import { useEffect, useState } from "react";

import { Button } from "@fronterahealth/frontera-ui-components";

import { MediaSortByEnum, MediaType, useGetMediaByProviderQuery } from "@api/graphql/types-and-hooks";
import { MediaCard } from "@pages/VideoSessions/components/MediaCard";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

export type MediaCardType = Pick<
  MediaType,
  "id" | "mediaType" | "mediaStatus" | "createdDate" | "therapySession" | "commentsCount"
>;
const ITEMS_PER_PAGE = 3;

export const MediaCards: React.FC = () => {
  const {
    filterSelectedClients,
    filterSelectedProviders,
    filterSessionDates,
    filterSelectedRating,
    sortBy,
    setTotalFilteredMediaCount,
  } = useVideoSessionData();

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching, isError } = useGetMediaByProviderQuery(
    {
      sortBy: (sortBy.id as unknown as MediaSortByEnum) || MediaSortByEnum.DescSessionUploadDate,
      learnerIds: filterSelectedClients.map((item) => item.id),
      providerIds: filterSelectedProviders.map((item) => item.id),
      sessionStartDate: filterSessionDates?.startDate ? format(filterSessionDates.startDate, "yyyy-MM-dd") : undefined,
      sessionEndDate: filterSessionDates?.endDate ? format(filterSessionDates.endDate, "yyyy-MM-dd") : undefined,
      sessionRating: parseInt(filterSelectedRating?.id || "") || undefined,
    },
    {
      queryKey: [
        "getMediaByProvider",
        filterSelectedClients,
        filterSelectedProviders,
        filterSessionDates,
        filterSelectedRating,
        sortBy,
      ],
      staleTime: 0,
    },
  );

  // Reset pagination when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [filterSelectedClients, filterSelectedProviders, filterSessionDates, filterSelectedRating, sortBy]);

  // Update total count
  useEffect(() => {
    if (data?.getMediaByProvider) {
      setTotalFilteredMediaCount(data.getMediaByProvider.totalCount || 0);
    }
  }, [data, setTotalFilteredMediaCount]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  // Get currently visible media items based on page
  const displayedMedia =
    data?.getMediaByProvider?.edges
      .slice(0, currentPage * ITEMS_PER_PAGE)
      .map((edge) => edge?.node as MediaCardType)
      .filter(Boolean) ?? [];

  if (isFetching && !data) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="w-6 h-6 animate-spin text-gray-500" />
      </div>
    );
  }

  if (isError) {
    return <div>Error loading media</div>;
  }

  const totalCount = data?.getMediaByProvider?.totalCount || 0;
  const hasMore = displayedMedia.length < totalCount;

  return (
    <div>
      <div className="flex flex-col gap-4 lg:px-6 lg:py-6 max-md:w-full">
        {displayedMedia.map((media) => (
          <MediaCard key={media.id} media={media} />
        ))}
      </div>

      {hasMore && (
        <div className="flex flex-col items-center mt-6 space-y-3">
          <Button
            onClick={handleLoadMore}
            disabled={isFetching}
            text={isFetching ? "Loading..." : "Load More"}
            appearance="secondary"
          />
        </div>
      )}
    </div>
  );
};
