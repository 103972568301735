import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";

import { Button, Folder, FolderExplorer, UserType } from "@fronterahealth/frontera-ui-components";

import {
  RecommendationBankItemType,
  useDeleteRecommendationBankItemMutation,
  useGetRecommendationBankItemsQuery,
} from "@api/graphql/types-and-hooks";
import { RecommendationBankCard } from "@components/RecommendationsBank/RecommendationBankCard";
import { RecommendationBankRecommendationsAddNewForm } from "@components/RecommendationsBank/RecommendationForm/RecommendationBankAddNewForm";
import { RecommendationsBankPanel } from "@components/RecommendationsBank/RecommendationsBankPanel";
import { RecommendationsEditForm } from "@components/RecommendationsBank/SavedRecommendations/RecommendationsEditForm";
import { DeleteFolderDialog } from "@components/RecommendationsBank/dialogs/DeleteFolderDialog";
import { useRecommendationsBankFolders } from "@components/RecommendationsBank/hooks/useRecommendationsBankFolders";
import { findFolder, getOrgFolderSection, getUserFolderSection } from "@components/RecommendationsBank/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

export const RecommendationsBank = () => {
  const { user } = useAuth0();
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const userType: UserType = !!user?.org_id ? "organization" : "selfServe";

  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const [selectedRecommendation, setSelectedRecommendation] = useState<RecommendationBankItemType | null>(null);

  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useRecommendationsBankFolders();

  const { data: recommendationBankItems, refetch, isFetching } = useGetRecommendationBankItemsQuery({});
  const { mutateAsync: deleteRecommendationBankItem } = useDeleteRecommendationBankItemMutation();

  const [openEditForm, setOpenEditForm] = useState<boolean>(false);

  const recommendationsToDisplay = useMemo(() => {
    return (
      recommendationBankItems?.getRecommendationBankItems
        ?.filter((edge) => edge.folder.id === selectedFolderId)
        .map((edge) => {
          const recommendation = edge;
          if (!recommendation) return null;

          return (
            <RecommendationBankCard
              key={recommendation.id}
              id={recommendation.id ?? ""}
              title={recommendation.recommendationTitle ?? ""}
              body={recommendation.recommendationContent ?? ""}
              onClick={() => {
                setSelectedRecommendation(recommendation as RecommendationBankItemType);
                setOpenEditForm(true);
              }}
              settings={{
                menuItems: [
                  {
                    label: "Edit",
                    Icon: PencilIcon,
                    callback: () => {
                      setSelectedRecommendation(recommendation as RecommendationBankItemType);
                      setOpenEditForm(true);
                    },
                  },
                  {
                    label: "Delete",
                    Icon: TrashIcon,
                    textColorClassName: "text-red-700",
                    callback: async () => {
                      const recommendationBankItemId = recommendation.id;
                      try {
                        await deleteRecommendationBankItem({
                          recommendationBankItemId,
                        });
                        notifySuccess("Successfully Recommendation");
                        await refetch();
                      } catch (err) {
                        console.error("Failed To Delete Recommendation", err);
                        notifyError("Failed To Delete Recommendation");
                      }
                    },
                  },
                ],
                openDirection: "to-bottom-left",
              }}
            />
          );
        }) || []
    );
  }, [recommendationBankItems, setSelectedRecommendation, setOpenEditForm, selectedFolderId]);

  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);

  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        userType={userType}
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId: string) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id: string, newValue: string) =>
          editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)
        }
        onCreateFolder={async (folderName: string, folderType: string) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title="Saved Recommendations"
        folderSections={orgName ? [userFoldersSection, orgFoldersSection] : [userFoldersSection]}
        modalButton={<Button appearance="secondary" text="Saved Recommendations" />}
        panel={
          <RecommendationsBankPanel
            userType={userType}
            addNewButton={() => {
              return (
                <RecommendationBankRecommendationsAddNewForm
                  folderId={selectedFolderId}
                  refetchRecommendations={async () => await refetch()}
                  hasRecommendations={recommendationsToDisplay.length > 0}
                />
              );
            }}
            isLoading={isFetching}
            key={userFolders.length + orgFolders.length}
            isEmpty={!userFolders.length && !orgFolders.length}
            recommendationsToDisplay={recommendationsToDisplay}
            onCreateFolder={createNewFolder}
            emptyState={{
              header: "No Recommendations Saved Yet",
              subHeader:
                "Start organizing your recommendations by creating a folder and adding your first recommendation.",
              buttonText: "+ Create New Folder",
            }}
          />
        }
      >
        {openEditForm && selectedRecommendation && (
          <RecommendationsEditForm
            openEditForm={openEditForm}
            setOpenEditForm={setOpenEditForm}
            refetchRecommendations={refetch}
            selectedRecommendation={selectedRecommendation}
          />
        )}
        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};
