import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiReportSectionsAiGeneratedStatusChoices, ReportPageLocationEnums } from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import {
  AssessmentReportSubRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { StickyNav } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { AddSection } from "@pages/AssessmentReportV2Details/Components/AddSection";
import { SectionWidgetWrapper } from "@pages/AssessmentReportV2Details/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";

const pageLocation: FilterSectionSubRoute = "report-part-1";

export const ReportSummaryPart1: React.FC = () => {
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const { pathname } = useLocation();
  const go = useNavigate();
  const { getNextRoute } = useOrderedAssessmentReportSubRoutes();
  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);
  const nextPage = getNextRoute(currentRoute) as FilterSectionSubRoute;
  const nextPageFilteredSections = useGetFilteredPageSectionsByLocation(nextPage);
  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);
  const nextPageAlreadyGenerated = nextPageFilteredSections.some(
    (section) =>
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending ||
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
  );

  const handlePrimaryButtonAction = async () => {
    if (!nextPageAlreadyGenerated) setPromptDialogOpen(true);
    else go(`../${nextPage}`);
  };

  return (
    <div className="flex w-full flex-col">
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.ReportPart_2}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`col-span-3 flex w-full flex-col gap-y-4`}>
          <AddSection newRankOrder={1} defaultNewSectionTitle="Enter Section Title" pageLocation={pageLocation} />
          {filteredSections.map((section) => (
            <div key={section.id}>
              <SectionWidgetWrapper
                section={section}
                permissions={{
                  canDelete: true,
                  canEdit: true,
                }}
                supportedContentTypes={["Text", "Table"]}
              />
              <AddSection
                newRankOrder={section.rankOrder + 1}
                defaultNewSectionTitle="Enter Section Title"
                pageLocation={pageLocation}
              />
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav state={filteredSections} />
        </div>
      </div>
      <FooterButtonRow primaryButtonTitle="Next" primaryButtonAction={handlePrimaryButtonAction} skipNavigate />
    </div>
  );
};
