import debounce from "lodash.debounce";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * This hook is responsible for listening to the location hash (via react router)
 * and scrolling to the correct element on the page based on the entry in the URL
 * @returns
 */
export const useScrollToAnchor = () => {
  const location = useLocation();
  const lastHash = useRef("");
  const [isScrolling, setIsScrolling] = useState<boolean>();

  useLayoutEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const headingSectionElement = document.getElementById(lastHash?.current);
        // NOTE: Magic CSS Number (Explained Below) = -160px
        /**
         * This value is needed in order to animate the clicked on section heading perfectly into the right scroll position
         * With no offset, the scrollTo will send the top of the entire window which is not we want
         *
         * We want to offset it down by the equivalent space that makes up
         * - The heading of the report builder page (includes client name) ~56px
         * - The TrainStop nav height ~64px
         * - The padding of the section card ~24px
         * - The margin between sections ~24px
         *
         * All this math results in a final scroll position which is visually where the user would expect the
         * clicked on section to show up. Yes it's hacky, but it results in a predictable animation
         */
        const yOffset = -160;
        if (!headingSectionElement) return;
        const headingSectionElementVerticalOffset =
          headingSectionElement.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: headingSectionElementVerticalOffset, behavior: "smooth" });
        setTimeout(() => {}, 1000);
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }
    let found = false;
    const handleScroll = debounce(() => {
      if (lastHash.current && document.getElementById(lastHash.current)) {
        const element = document.getElementById(lastHash.current);
        if (element && !found) {
          const bounding = element.getBoundingClientRect();
          if (bounding.top >= 0 && bounding.top <= window.innerHeight / 2) {
            found = true;
            setIsScrolling(false);
          } else {
            setIsScrolling(true);
          }
        }
      }
    }, 0);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  return isScrolling;
};
