import { Navigate, useLocation } from "react-router-dom";

import { AssessmentType } from "@api/graphql/types-and-hooks";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export const getProperABRedirectPathFromReport = (report: AssessmentType) => {
  const reportIsCompleted = report.assessmentStatus === "COMPLETED";

  const pageOneGenerating = report.aiReport1PredictionStatus === "REPORT_FIELD_PREDICTION_PENDING";
  const pageOneGenerated = report.aiReport1PredictionStatus === "REPORT_FIELD_PREDICTION_PREDICTED";

  const pageTwoGenerating = report.aiReport2PredictionStatus === "REPORT_FIELD_PREDICTION_PENDING";
  const pageTwoGenerated = report.aiReport2PredictionStatus === "REPORT_FIELD_PREDICTION_PREDICTED";

  const ltgGenerating = report.aiLtgPredictionStatus === "GOAL_PREDICTION_PENDING";
  const ltgGenerated = report.aiLtgPredictionStatus === "GOAL_PREDICTION_PREDICTED";

  const stgGenerating = report.aiStgPredictionStatus === "GOAL_PREDICTION_PENDING";
  const stgGenerated = report.aiStgPredictionStatus === "GOAL_PREDICTION_PREDICTED";

  const reportHasClient = !!report?.learner?.id;

  switch (true) {
    case reportIsCompleted:
      return `review-and-sign`;
    case pageTwoGenerating || pageTwoGenerated:
      return `report-summary-part-2`;
    case stgGenerating || stgGenerated:
      return `short-term-goals`;
    case ltgGenerating || ltgGenerated:
      return `long-term-goals`;
    case pageOneGenerating || pageOneGenerated:
      return `report-summary-part-1`;
    case reportHasClient:
      return `upload-files`;
    default:
      return `client-details`;
  }
};

export const AssessmentReportDetailsRedirect = () => {
  const { state } = useLocation();
  const { assessmentReport } = useAssessmentBuilderData();

  const pathToRedirectTo = getProperABRedirectPathFromReport(assessmentReport);

  return <Navigate to={pathToRedirectTo} replace state={state} />;
};
