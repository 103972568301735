import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

import { Input } from "@fronterahealth/frontera-ui-components";

import {
  ApiMediaCommentsCommentTypeChoices,
  MediaCommentEnum,
  useCreateMediaCommentMutation,
} from "@api/graphql/types-and-hooks";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface CommentFormProps {
  parentId: string;
  mediaStartTime: number;
  commentType: ApiMediaCommentsCommentTypeChoices | undefined;
  handleCommentSubmit: () => void;
}
const CommentForm: React.FC<CommentFormProps> = ({ parentId, mediaStartTime, commentType, handleCommentSubmit }) => {
  const [comment, setComment] = useState<string>("");
  const { selectedMedia, commentsByMediaIdQuery } = useVideoSessionData();
  const createCommentMutation = useCreateMediaCommentMutation();

  // Handle comment input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  // Handle form submission (for example, just log the comment)
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (comment.trim()) {
      await createCommentMutation
        .mutateAsync({
          input: {
            content: comment.trim(),
            commentType: commentType as unknown as MediaCommentEnum,
            mediaId: selectedMedia?.id || "",
            mediaStartTime: mediaStartTime || 0,
            parentCommentId: parentId,
          },
        })
        .then((data) => {
          if (data.createMediaComment?.status) {
            handleCommentSubmit();
            commentsByMediaIdQuery.refetch();
          }
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative w-full">
      <Input
        label=""
        name="comment"
        type="text"
        value={comment}
        onChange={handleChange}
        placeholder="Leave your comment..."
        className="w-full"
        inputSize="full"
        formNoValidate
      />
      <button
        className="absolute top-[14px] right-[5px]"
        disabled={!comment.trim()} // Disable if input is empty
      >
        <PaperAirplaneIcon className="h-6 w-6 text-limestone-600 bg-white" />
      </button>
    </form>
  );
};

export default CommentForm;
