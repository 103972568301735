import { XMarkIcon } from "@heroicons/react/24/outline";

import {
  BucketInformation,
  CreateFileInformation,
  FileUploadAndParse,
  ParsingInformation,
  Small,
  UploadFileInformation,
} from "@fronterahealth/frontera-ui-components";
import { PopoverMenuItem } from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

interface UploadLogoProps {
  logoSignedUrl: string;
  onSave: () => Promise<unknown>;
  displayOnlyMode: boolean;
  S3BucketUrlRetriever: (params: CreateFileInformation) => Promise<BucketInformation>;
  S3BucketUploadCallback: (params: UploadFileInformation) => Promise<void>;
  reportFileUploadCompleteCallback: (fileId: string) => Promise<void>;
  fileParseStatusRetriever: (fileId: string) => Promise<ParsingInformation>;
  removeFileCallback: (fileId: string) => Promise<void>;
  actionButtonMenu?: PopoverMenuItem[];
}

const UploadLogo: React.FC<UploadLogoProps> = ({
  logoSignedUrl,
  onSave,
  S3BucketUrlRetriever,
  S3BucketUploadCallback,
  reportFileUploadCompleteCallback,
  fileParseStatusRetriever,
  removeFileCallback,
  displayOnlyMode,
}) => {
  const renderImage = () => (
    <div className="flex justify-center items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:bg-limestone-50">
      <div className="rounded-xl relative">
        {displayOnlyMode && !logoSignedUrl ? (
          <div className="p-2">
            <Small>logo not available</Small>
          </div>
        ) : (
          <>
            <img
              src={logoSignedUrl || ""}
              alt="Invalid URL"
              className="max-w-full h-48 object-contain p-2 rounded-xl"
            />
            {displayOnlyMode ? null : (
              <XMarkIcon
                onClick={async () => await onSave()}
                className="h-6 w-6 absolute top-2 right-2 cursor-pointer rounded-xl bg-green-700 p-1 text-white transition-all"
              />
            )}
          </>
        )}
      </div>
    </div>
  );

  const renderFileUpload = () => (
    <div className="-my-2">
      <FileUploadAndParse
        key={Math.random()}
        fileKind={""}
        existingFiles={[]}
        title=""
        singleFileUpload
        supportedFileTypes={["png", "jpg"]}
        maxSize={"100MB"}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
      />
    </div>
  );

  return (
    <div className="group relative pr-6">
      <div data-testid="image-input-container">
        <div className="relative w-64">{displayOnlyMode || logoSignedUrl ? renderImage() : renderFileUpload()}</div>
      </div>
    </div>
  );
};

export default UploadLogo;
