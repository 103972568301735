import React from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

type NoDataProps = {
  title: string;
  description: string;
  showBorder?: boolean;
};

export const NoData: React.FC<NoDataProps> = ({ title, description, showBorder = true }) => {
  return (
    <div
      className={`${showBorder ? "border  border-limestone-200" : ""} w-full rounded-md flex flex-col gap-2 p-4 max-w-md mx-auto text-center bg-white`}
    >
      <Paragraph displayType="loud" colorType="primary">
        {title}
      </Paragraph>
      <Small colorType="secondary" className="leading-5">
        {description}
      </Small>
    </div>
  );
};
