import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { PlusIcon } from "@heroicons/react/24/outline";
import { QueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";

import {
  AI_GENERATED,
  Button,
  Folder,
  FolderExplorer,
  GoalBankPanel,
  Small,
  UserType,
} from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedLongTermGoalsType,
  AiSuggestedLongTermGoalsTypeEdge,
  ApiAiSuggestedLongTermGoalsGoalTypeChoices,
  CreateGoalBankLongTermGoalsInput,
  GoalTypeEnums,
  LongTermGoalBankInput,
  useAddSuggestedLongGoalsMutation,
  useCreateGoalBankLongTermGoalsMutation,
  useCreateUpdateLongTermGoalMutation,
  useGoalBankLongTermGoalsQuery,
} from "@api/graphql/types-and-hooks";
import { AddLongTermGoalsBankCard } from "@components/GoalsBank/AddLongTermGoalsBank/AddLongTermGoalsBankCard";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { DeleteFolderDialog } from "@components/GoalsBank/dialogs/DeleteFolderDialog";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolder,
  getAiGeneratedFolderSection,
  getFolderSelections,
  getOrgFolderSection,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

interface AddLongTermGoalBankProps {
  onAdd: () => void;
  assessmentId: string;
  aiGoals: AiSuggestedLongTermGoalsTypeEdge[];
  learnerId: string;
}

export const AddLongTermGoalsBank: React.FC<AddLongTermGoalBankProps> = ({
  aiGoals,
  assessmentId,
  onAdd,
  learnerId,
}) => {
  const [queryClient] = useState(() => new QueryClient());
  const { user } = useAuth0();
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const userType: UserType = !!user?.org_id ? "organization" : "selfServe";
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const [isSaveToFolderDialogOpen, setSaveToFolderDialogOpen] = useState<boolean>(false);
  const [selectedGoalIds, setSelectedGoalIds] = useState<string[]>([]);
  const [selectedAiGoalIds, setSelectedAiGoalIds] = useState<string[]>([]);
  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useGoalBankFolders();

  const addSuggestedLongGoalsMutation = useAddSuggestedLongGoalsMutation({});
  const createLongTermGoalBank = useCreateGoalBankLongTermGoalsMutation({});
  const createUpdateLongTermGoalMutation = useCreateUpdateLongTermGoalMutation({});
  const goalBankLongTermGoals = useGoalBankLongTermGoalsQuery(
    { folderId: selectedFolderId },
    {
      queryKey: ["long-term-goals-" + selectedFolderId],
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchInterval: 200000,
      enabled: !!selectedFolderId && !selectedFolderId.startsWith(AI_GENERATED),
    },
  );

  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const aiSuggestedFoldersSection = getAiGeneratedFolderSection();
  aiSuggestedFoldersSection.folders = [];
  const aiFolderNames: string[] = [];
  const longTermGoalTypes = Object.values(ApiAiSuggestedLongTermGoalsGoalTypeChoices);
  const aiLongTermGoalsMap = new Map<string, AiSuggestedLongTermGoalsTypeEdge[]>();

  longTermGoalTypes.forEach((choice) => {
    const folderName = convertReadableString(choice);
    const key = AI_GENERATED + "_" + choice;
    aiLongTermGoalsMap.set(
      key,
      aiGoals.filter((edge) => edge.node?.goalType === choice),
    );
    if (!aiFolderNames.includes(folderName)) {
      aiSuggestedFoldersSection.folders.push({
        name: folderName + " (" + aiLongTermGoalsMap.get(key)?.length + ")",
        id: key,
      } as Folder);
    }
  });

  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);

  const goalsToAdd = (): ReactNode[] => {
    if (selectedFolderId.startsWith(AI_GENERATED)) {
      return (aiLongTermGoalsMap.get(selectedFolderId) ?? []).map((edge) => {
        const node = edge?.node as AiSuggestedLongTermGoalsType;
        return node ? (
          <AddLongTermGoalsBankCard
            id={node.id}
            goalName={node.goalName}
            goalType={node.goalType}
            description={node.description}
            selected={selectedAiGoalIds.includes(node.id)}
            onClick={() => {
              if (selectedAiGoalIds.includes(node.id)) {
                setSelectedAiGoalIds([...selectedAiGoalIds.filter((id) => id != node?.id)]);
                return;
              }
              setSelectedAiGoalIds([...selectedAiGoalIds, node.id]);
            }}
          />
        ) : (
          <></>
        );
      });
    }
    return (
      goalBankLongTermGoals.data?.getGoalBankLongTermGoals?.edges.map((edge) => {
        const node = edge?.node;
        return node ? (
          <AddLongTermGoalsBankCard
            id={node.id}
            goalName={node.goalName}
            goalType={node.goalType}
            description={node.description}
            selected={selectedGoalIds.includes(node.id)}
            onClick={() => {
              if (selectedGoalIds.includes(node.id)) {
                setSelectedGoalIds([...selectedGoalIds.filter((id) => id != node?.id)]);
                return;
              }
              setSelectedGoalIds([...selectedGoalIds, node.id]);
            }}
          />
        ) : (
          <></>
        );
      }) || []
    );
  };

  const addGoals = async () => {
    if (selectedGoalIds || selectedAiGoalIds) {
      try {
        if (selectedAiGoalIds) {
          addSuggestedLongGoalsMutation.mutate(
            {
              assessmentId: assessmentId || "<missing-assessment-id>",
              aiSuggestedLongGoalIds: selectedAiGoalIds,
            },
            {
              onSuccess: async () => {
                await onAdd();
              },
            },
          );
        }
        const goalsToAdd = goalBankLongTermGoals.data?.getGoalBankLongTermGoals?.edges.filter((edge) =>
          selectedGoalIds.includes(edge?.node?.id ?? "<missing-long-term-goal-id>"),
        );
        await goalsToAdd?.forEach(async (goal) => {
          const node = goal?.node;
          await createUpdateLongTermGoalMutation.mutateAsync(
            {
              longTermData: {
                goalName: node?.goalName ?? "",
                goalType: convertDBString(node?.goalType ?? "") as unknown as GoalTypeEnums,
                description: node?.description,
                assessmentId,
                longTermGoalBankId: node?.id,
              },
              learnerId: learnerId ? learnerId : "<missing-learner-id>",
            },
            {
              onSuccess: async () => {
                await onAdd();
              },
            },
          );
        });
      } catch (err) {
        notifyError("Failed To Add Goal(s)");
        console.error("Error adding AI goals", err);
      } finally {
        setSelectedAiGoalIds([]);
        setSelectedGoalIds([]);
        notifySuccess("Successfully Added Goal(s)");
      }
    }
  };

  const { formState, RegisteredFormSelected, reset, onSubmit } = useFormUtils<CreateGoalBankLongTermGoalsInput>({
    mutationFn: async (params) => {
      if (params.folderId)
        try {
          createLongTermGoalBank.mutate(
            {
              input: {
                folderId: params.folderId,
                longtermGoalsData: aiGoals
                  .filter((goal) => selectedAiGoalIds.includes(goal.node?.id ?? ""))
                  .map((goal) => {
                    const node = goal.node as AiSuggestedLongTermGoalsType;
                    return {
                      aiSuggestedLongGoal: node.id,
                      description: node.description,
                      goalName: node.goalName,
                      goalType: convertDBString(node.goalType) as unknown as GoalTypeEnums,
                    } as LongTermGoalBankInput;
                  }),
              },
            },
            {
              onSuccess: async () => {
                notifySuccess("Saved To Folder");
                setSaveToFolderDialogOpen(false);
                setSelectedGoalIds([]);
                setSelectedAiGoalIds([]);
                onAdd();
                await queryClient.refetchQueries();
              },
            },
          );
        } catch (err) {
          notifyError("Failed To Save To Folder");
          console.error("Error saving to folder", err);
        }
    },
  });

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        userType={userType}
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id, newValue) => editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)}
        onCreateFolder={async (folderName, folderType) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title={"Add Long Term Goals"}
        folderSections={
          orgName
            ? [aiSuggestedFoldersSection, userFoldersSection, orgFoldersSection]
            : [aiSuggestedFoldersSection, userFoldersSection]
        }
        modalButton={
          <Button
            text={
              <Small className={"flex items-center px-2 text-secondary"}>
                <PlusIcon className="mr-2 h-4 w-4" />
                Suggested Goal
              </Small>
            }
            id="suggested-goal-btn"
            appearance="secondary"
            buttonAction="destructive"
            className="hover:bg-transparent ring-globemallow-600 hover:ring-globemallow-500 active:ring-globemallow-900"
          />
        }
        footer={{
          addButtonText: "Add Goal(s)",
          onAdd: addGoals,
          onSaveToFolder: () => setSaveToFolderDialogOpen(true),
          displaySaveToFolderButton: selectedFolderId.startsWith(AI_GENERATED),
        }}
        panel={
          <GoalBankPanel
            userType={userType}
            isLoading={goalBankLongTermGoals.isLoading || goalBankLongTermGoals.isFetching}
            key={goalsToAdd().length}
            noFolders={false}
            goalsToDisplay={goalBankLongTermGoals.isLoading || goalBankLongTermGoals.isFetching ? [] : goalsToAdd()}
            goalsTabs={[]}
            onCreateFolder={() => {}}
          ></GoalBankPanel>
        }
      >
        <FolderManagementDialog
          key={userFolders.length + orgFolders.length}
          open={isSaveToFolderDialogOpen}
          action={FolderManagementAction.SAVE}
          onCancelClick={() => {
            reset();
            setSaveToFolderDialogOpen(false);
          }}
          onSaveClick={onSubmit}
        >
          <FormContainer onSubmit={onSubmit}>
            <RegisteredFormSelected
              formKey="folderId"
              required
              formState={formState}
              items={getFolderSelections(userFoldersSection, orgFoldersSection)}
              title={"Select Folder"}
              placeholderText={"Select Folder"}
            />
          </FormContainer>
        </FolderManagementDialog>
        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};
