import { datadogRum } from "@datadog/browser-rum";
import { useParams } from "react-router-dom";

import {
  useCreateReportSectionFieldFileMutation,
  useUpdateReportSectionFieldFileUrlMutation,
} from "@api/graphql/types-and-hooks";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

type FileParams = {
  fileKind: string;
  fileName: string;
  mimeType: string;
  fileSize: number;
  fieldId: string;
};

type ExtendedFileUploadAndParseProps = {
  S3BucketUrlRetriever: (params: FileParams) => Promise<{ bucket: string; fileId: string }>;
  S3BucketUploadCallback: (params: { file: File; url: string; mimeType: string; fieldId: string }) => Promise<void>;
};

export const useGetS3BucketUrlRetriever: ({
  metadata,
  fieldId,
}: {
  metadata?: object;
  fieldId?: string;
}) => ExtendedFileUploadAndParseProps["S3BucketUrlRetriever"] = ({ metadata: _metadata }) => {
  const { evaluationId: reportId } = useParams();
  const createFileMutation = useCreateReportSectionFieldFileMutation({ throwOnError: false });
  const metadata = _metadata ? JSON.stringify(_metadata) : undefined;

  return async ({ fileKind, fileName, mimeType, fileSize, fieldId }) => {
    console.debug(
      `%c<FILE-API> | Requesting S3 URL | fileKind: ${fileKind} fileName: ${fileName} mimeType: ${mimeType} reportId: ${reportId} metadata: ${metadata}`,
      "color: black; background: red",
    );

    try {
      const res = await createFileMutation.mutateAsync({
        input: {
          fileSize,
          mimeType,
          originalFileName: fileName,
          reportId: reportId || "<missing-report-id>",
          fieldId: fieldId || "<missing-field-id>",
        },
      });

      datadogRum.addAction("create-field-file", {
        fileMetadata: { fileKind, mimeType, fileName, metadata, fileId: fieldId },
      });

      const bucket = res?.createReportSectionFieldFile?.fileUrl || "<missing-bucket-url>";
      const fileId = fieldId || "<missing-file-id>";

      return { bucket, fileId };
    } catch (err) {
      console.error("Error retrieving S3 bucket URL:", err);
      throw new Error(String(err));
    }
  };
};

export const useGetS3BucketUploadCallback: () => ExtendedFileUploadAndParseProps["S3BucketUploadCallback"] = () => {
  const updateFileUrlMutation = useUpdateReportSectionFieldFileUrlMutation({ throwOnError: false });
  const { assessmentQuery } = useAssessmentBuilderData();
  const { refetch } = assessmentQuery;

  return async ({ file, mimeType, url, fieldId }) => {
    console.debug(`Uploading file: ${file.name} to S3 URL: ${url}`);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": mimeType },
        body: file,
      });

      if (response.status !== 200) {
        console.error("Failed to upload file to S3", response.statusText);
        throw new Error(`S3 upload failed: ${response.status} ${response.statusText}`);
      }

      datadogRum.addAction("field-file-uploaded-successfully", { fileMetadata: { fileName: file.name, mimeType } });

      const res = await updateFileUrlMutation.mutateAsync({
        input: {
          reportId: assessmentQuery.data?.getReports?.edges[0]?.node?.id ?? "<missing-report-id>",
          fieldId: fieldId ?? "<missing-section-field-id>",
          originalFileName: file.name,
        },
      });

      if (res?.updateReportSectionFieldFileUrl?.status) {
        await refetch();
      }
    } catch (err) {
      console.error("Error uploading file to S3:", err);
      throw new Error(String(err));
    }
  };
};
