import {
  ApiMediaCommentsCommentTypeChoices,
  GetMediaCommentRepliesQuery,
  GetMediaCommentsByMediaIdQuery,
} from "@api/graphql/types-and-hooks";
import { EMOJI_BUTTONS } from "@pages/VideoSessions/const";

export interface Comment {
  id: string;
  authorFirstName: string;
  authorLastName: string;
  time: number;
  type?: ApiMediaCommentsCommentTypeChoices | undefined;
  content: string;
  lastReply?: Comment[];
  parentCommentID?: string | undefined | null;
  totalReplies: number;
  createdDate: string;
  auth0Id: string;
}
export const transformCommentData = (data: GetMediaCommentsByMediaIdQuery): Comment[] => {
  return (
    (data.getMediaComments?.edges.map((item) => ({
      id: item?.node?.id || "",
      auth0Id: item?.node?.author?.auth0id || "",
      authorFirstName: `${item?.node?.author?.provider?.providermetadata?.firstName || "N/A"} `.trim(),
      authorLastName: item?.node?.author?.provider?.providermetadata?.lastName || "",
      time: item?.node?.mediaStartTime,
      type: item?.node?.commentType,
      content: item?.node?.content || "",
      parentCommentID: item?.node?.parentComment?.id || undefined,
      createdDate: item?.node?.createdDate || "",
      lastReply: item?.node?.commentReplies?.edges.map((reply) => ({
        id: reply?.node?.id || "",
        authorFirstName: `${reply?.node?.author?.provider?.providermetadata?.firstName || "N/A"} `.trim(),
        authorLastName: reply?.node?.author?.provider?.providermetadata?.lastName || "",
        type: reply?.node?.commentType,
        content: reply?.node?.content || "",
        totalReplies: 0,
        createdDate: reply?.node?.createdDate || "",
        parentCommentID: reply?.node?.parentComment?.id || undefined,
        auth0Id: reply?.node?.author?.auth0id || "",
      })),
      totalReplies: item?.node?.commentReplies?.totalCount || 0,
    })) as unknown as Comment[]) || []
  );
};

export const transformRepliesData = (data: GetMediaCommentRepliesQuery): Comment[] => {
  return (
    (data?.getMediaCommentReplies?.edges.map((reply) => ({
      id: reply?.node?.id || "",
      auth0Id: reply?.node?.author?.auth0id || "",
      authorFirstName: `${reply?.node?.author?.provider?.providermetadata?.firstName || "N/A"} `.trim(),
      authorLastName: reply?.node?.author?.provider?.providermetadata?.lastName || "",
      type: reply?.node?.commentType,
      content: reply?.node?.content || "",
      totalReplies: 0,
      createdDate: reply?.node?.createdDate || "",
      parentCommentID: reply?.node?.parentComment?.id || undefined,
    })) as unknown as Comment[]) || []
  );
};

export const getEmojiByValue = (value: ApiMediaCommentsCommentTypeChoices): string | undefined => {
  const button = EMOJI_BUTTONS.find((button) => button.value === value);
  return button?.emoji;
};
