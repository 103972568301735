import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import { Button } from "@fronterahealth/frontera-ui-components";

import { useCreateReportSectionsMutation } from "@api/graphql/types-and-hooks";
import { NoDataCard } from "@components/NoDataCard/NoDataCard";
import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import { AddSection } from "@pages/EvaluationDetails/Components/AddSection";
import { SectionWidgetWrapper } from "@pages/EvaluationDetails/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/EvaluationDetails/Components/helper";
import { FooterButtonRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/FooterButtonRow";
import { StickyNav } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/StickyNav";
import { useEvaluationData } from "@providers/EvaluationProvider";

const pageLocation: FilterSectionSubRoute = "appendix";

export const EvaluationAppendix: React.FC = () => {
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const flags = useFlags<FeatureFlags>();

  const supportedContentTypes = ["Text", "Table"] as SupportedContentType[];
  if (flags["upload-images-component"]) {
    supportedContentTypes.push("Image");
  }

  const { evaluationQuery } = useEvaluationData();

  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node;
  if (!evaluation) return null;

  const createSection = useCreateReportSectionsMutation({});
  const { isPending } = createSection;
  const onAddSection = async () => {
    await createSection
      .mutateAsync({
        reportId: evaluation.id,
        runWorkflow: false,
        sections: [{ title: "Enter Section Title", pageLocation: pageLocation, rankOrder: 1 }],
      })
      .then((data) => {
        if (data.createReportSections?.status) {
          evaluationQuery?.refetch();
        }
      });
  };

  return (
    <div className="flex w-full flex-col pb-6">
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`col-span-3 flex w-full flex-col gap-y-4`}>
          {filteredSections.length > 0 ? (
            <AddSection newRankOrder={1} defaultNewSectionTitle="Enter Section Title" pageLocation={pageLocation} />
          ) : (
            <>
              <NoDataCard
                title="You have no appendix section added"
                description={<span>Appendix section that you add will end up here.</span>}
              >
                <Button
                  text={isPending ? "Adding..." : "Add Appendix Section"}
                  appearance="primary"
                  buttonAction="action"
                  disabled={isPending}
                  className="mt-2"
                  onClick={() => onAddSection()}
                />
              </NoDataCard>
            </>
          )}
          {filteredSections.map((section) => (
            <div key={section.id}>
              <SectionWidgetWrapper
                section={section}
                permissions={{
                  canDelete: true,
                  canEdit: true,
                }}
                supportedContentTypes={supportedContentTypes}
              />
              <AddSection
                newRankOrder={section.rankOrder + 1}
                defaultNewSectionTitle="Enter Section Title"
                pageLocation={pageLocation}
              />
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav state={filteredSections} />
        </div>
      </div>
      <FooterButtonRow primaryButtonTitle={"Sign & Complete"} />
    </div>
  );
};
