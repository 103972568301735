import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";

import { Input, Select, SelectItem } from "@fronterahealth/frontera-ui-components";

import { TranscriptLineRecordTypeEdge } from "@api/graphql/types-and-hooks";
import { NoData } from "@pages/VideoSessions/components/NoData/NoData";
import { TranscriptLineView } from "@pages/VideoSessions/components/TranscriptLineView";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface VideoTranscriptProps {
  currentTime: number;
  onClick: (time: number) => void;
}

export const VideoTranscript: React.FC<VideoTranscriptProps> = ({ onClick }) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const transcriptRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [searchText, setSearchText] = useState<string>("");
  const [selectedSpeaker, setSelectedSpeaker] = useState<SelectItem | null>(null);
  const [filteredTranscript, setFilteredTranscript] = useState<TranscriptLineRecordTypeEdge[]>([]);
  const { allTranscriptData, isTranscriptLoading } = useVideoSessionData();

  // WE CAN USE THIS IN FUTURE IF WE WANT TRANSCRIPT GOT SELECTED WITH VIDEO SCRUBBING

  //   useEffect(() => {
  //     if (currentTime) {
  //       const currentTranscript = data?.getTranscriptDataByMediaId?.edges.find((item) => {
  //         return item?.node && currentTime >= item?.node.startTime && currentTime <= item?.node.endTime;
  //       });

  //       // Update highlighted transcript if the current time falls within the range
  //       if (currentTranscript && currentTranscript.node && currentTranscript.node.id !== selectedId) {
  //         setSelectedId(currentTranscript.node.id);
  //       } else if (!currentTranscript) {
  //         setSelectedId(null); // Clear highlight if no transcript matches
  //       }
  //     }
  //   }, [currentTime]);

  // Filter transcript lines based on searchText and speaker
  useEffect(() => {
    if (allTranscriptData.length) {
      const filtered = allTranscriptData.filter((item) => {
        const conversationMatch = searchText.trim()
          ? item?.node?.conversation.toLowerCase().includes(searchText.toLowerCase())
          : true;
        const speakerMatch = selectedSpeaker?.primary
          ? item?.node?.speaker.toLowerCase() === selectedSpeaker.primary.toLowerCase()
          : true;
        return conversationMatch && speakerMatch;
      });
      setFilteredTranscript(filtered as TranscriptLineRecordTypeEdge[]);
    }
  }, [searchText, selectedSpeaker, allTranscriptData]);

  useEffect(() => {
    if (selectedId && transcriptRefs.current[selectedId]) {
      transcriptRefs.current[selectedId]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedId]);
  const handleLineClick = (time: number, id: string) => {
    setSelectedId(id); // Update the selected line ID
    onClick(time); // Trigger the provided onClick handler
  };

  const highlightText = (text: string) => {
    if (!searchText) return text; // No highlight if no search text
    const regex = new RegExp(`(${searchText})`, "gi"); // Regular expression to find the search text, case insensitive
    const parts = text.split(regex); // Split the text into parts based on the search text
    return parts.map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span key={index} className="bg-orange-200">
          {part}
        </span> // Highlight the matched part
      ) : (
        part // Render the non-matched part as plain text
      ),
    );
  };

  return (
    <div className="lg:bg-white bg-limestone-50 rounded-lg p-4 pt-4 gap-3 grid grid-flow-row auto-rows-max cursor-pointer max-lg:min-h-[calc(100vh-18rem)] lg:overflow-y-auto lg:h-[calc(100vh-10rem)] ">
      {isTranscriptLoading ? (
        <DataLoading text="Transcript Loading" />
      ) : allTranscriptData.length ? (
        <>
          <div className="relative w-full max-w-full">
            <Input
              name="search"
              label=""
              inputSize="full"
              formNoValidate
              value={searchText}
              placeholder="Search for keyword..."
              onChange={(e) => setSearchText(e.target.value)}
            />
            <MagnifyingGlassIcon className="absolute top-[55%] right-3 h-5 w-5 text-gray-400 transform -translate-y-1/2" />
          </div>
          <Select
            title=""
            placeholderText="Speaker"
            items={[{ primary: "Child" }, { primary: "Therapist" }]}
            selected={selectedSpeaker}
            setSelected={setSelectedSpeaker}
            actionItem={{
              label: "Clear",
              onClick: () => {
                setSelectedSpeaker(null);
              },
            }}
            className="max-w-40"
            formNoValidate
            inputContainerClassName="mt-0"
          />

          <div className="flex flex-col gap-3">
            {filteredTranscript.map((item) => (
              <TranscriptLineView
                ref={(el) => (item?.node ? (transcriptRefs.current[item?.node.id] = el) : "")}
                conversation={highlightText(item?.node?.conversation || "")}
                speaker={item?.node?.speaker || ""}
                startTime={item?.node?.startTime !== undefined ? item.node.startTime : 0}
                endTime={item?.node?.endTime || 0}
                transcriptRecordId={item?.node?.id || ""}
                key={item?.node?.id}
                isSelected={selectedId === item?.node?.id} // Pass selected status
                onClick={handleLineClick}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="mt-4">
          <NoData
            title="Transcript Unavailable for This Session"
            description="We couldn’t generate a transcript for this video. This might be due to system limitations or the session’s audio quality. Please review the video directly for any details or insights you need."
          />
        </div>
      )}
    </div>
  );
};
