import React from "react";

import { Heading, WYSIWYG } from "@fronterahealth/frontera-ui-components";

import { useGetSessionSummaryBySessionIdQuery } from "@api/graphql/types-and-hooks";
import { NoData } from "@pages/VideoSessions/components/NoData/NoData";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface VideoSummaryProps {}

export const VideoSummary: React.FC<VideoSummaryProps> = () => {
  const { selectedMedia } = useVideoSessionData();
  const { data, isFetching } = useGetSessionSummaryBySessionIdQuery(
    {
      therapySessionId: selectedMedia?.therapySession?.id || "",
    },
    {
      enabled: Boolean(selectedMedia),
    },
  );

  const sessionSummary = data?.getSessionSummaryBySessionId?.prediction;
  const trimmedSummary = sessionSummary ? sessionSummary?.replace(/Session Description:\s*/i, "").trim() : "";

  return (
    <div className="pt-8 px-8 py-8 flex flex-col gap-4 bg-white lg:overflow-y-auto max-lg:min-h-[calc(100vh-18rem)]">
      <Heading type="h3" className="text-text-primary">
        Session Summary
      </Heading>

      {isFetching ? (
        <div className="flex w-full flex-row justify-center">
          <DataLoading text="Session Summary loading.." />
        </div>
      ) : data && data.getSessionSummaryBySessionId ? (
        <WYSIWYG
          displayOnlyMode
          title=""
          onSave={async () => {}}
          isSaving={false}
          placeholder=""
          existingContent={trimmedSummary}
        />
      ) : (
        <NoData
          title="Summary Unavailable for This Session"
          description="We couldn’t generate a session summary for this video. This might happen due to certain system limitations or the nature of the session content. Please review the video directly for any insights or observations you need."
        />
      )}
    </div>
  );
};
