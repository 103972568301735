import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { useUpdateReportLogoUrlMutation, useUpdateReportPageHeadingMutation } from "@api/graphql/types-and-hooks";
import { BespokeInputField } from "@components/FieldComponent/BespokeInputField";
import { FieldComponent } from "@components/FieldComponent/FieldComponent";
import { SectionWidget } from "@components/SectionWidget/SectionWidget";
import { notifyError } from "@components/notifications/notifications";
import UploadLogo from "@pages/EvaluationDetails/Components/UploadLogo";
import { emptyFunction } from "@pages/EvaluationDetails/Components/helper";
import {
  useGetS3BucketLogoUrlRetriever,
  useGetS3BucketUploadLogoCallback,
} from "@pages/EvaluationDetails/Components/hooks";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface CustomFieldProps {
  title: string;
  content: string;
}

export const CustomField: React.FC<CustomFieldProps> = ({ title, content }) => {
  return (
    <div className="mb-4 mt-4 flex flex-col">
      <label className="block">
        <Small className="">{title}</Small>
      </label>
      <Paragraph displayType="normal" colorType="primary">
        {content}
      </Paragraph>
    </div>
  );
};

interface ReportHeaderSectionProps {
  reportIsCompleted: boolean;
}

export const ReportHeaderSection: React.FC<ReportHeaderSectionProps> = ({ reportIsCompleted }) => {
  const { evaluationQuery } = useEvaluationData();
  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node;

  const permissions = { canEdit: false, canDelete: false };

  const S3BucketUrlRetriever = useGetS3BucketLogoUrlRetriever({});
  const S3BucketUploadCallback = useGetS3BucketUploadLogoCallback();
  const updateContent = useUpdateReportPageHeadingMutation();
  const updateFileUrlMutation = useUpdateReportLogoUrlMutation();

  const { isPending } = updateContent;

  const handleOnSave = async (content: string) => {
    await updateContent
      .mutateAsync({
        input: { reportId: evaluation?.id ?? "<missing-report-id>", pageHeadingText: content },
      })
      .then((data) => {
        if (data.updateReportPageHeading?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating report heading : ", error);
        notifyError("Error Updating Report Heading");
      });
  };

  const onSave = async () => {
    await updateFileUrlMutation
      .mutateAsync({
        input: { reportId: evaluation?.id ?? "<missing-report-id>" },
      })
      .then((data) => {
        if (data.updateReportLogoUrl?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error removing the header logo : ", error);
        notifyError("Error removing the header logo");
      });
  };

  return (
    <SectionWidget
      sectionType={"regular"}
      key="Report-Header"
      id="ReportHeader"
      onAddContent={emptyFunction}
      onEditSection={emptyFunction}
      permissions={permissions}
      sectionData={{ heading: "Report Header", icon: "document" }}
      supportedContentTypes={[]}
      placeholder=""
      allowInLineEdit={false}
      setIsInLineEditMode={() => {}}
      isInLineEditMode={false}
      actionButtonMenu={[]}
      isLoading={false}
      isError={false}
    >
      <div key={`logo-logo`}>
        <FieldComponent
          key={"logo-logo"}
          id={"logo-logo"}
          onEditTitle={emptyFunction}
          permissions={permissions}
          title={"Upload Logo"}
          placeholder=""
        >
          <div className={`mt-2 ${evaluation?.reportHeaderLogoSignedUrl || reportIsCompleted ? "-mb-2" : "-mb-8"} `}>
            <UploadLogo
              S3BucketUrlRetriever={(params) =>
                S3BucketUrlRetriever({
                  ...params,
                })
              }
              S3BucketUploadCallback={(params) =>
                S3BucketUploadCallback({
                  ...params,
                })
              }
              reportFileUploadCompleteCallback={() => Promise.resolve()}
              fileParseStatusRetriever={(fileId) =>
                new Promise((resolve) => {
                  setTimeout(() => resolve({ fileId, status: "done" }), 1000);
                })
              }
              removeFileCallback={() => Promise.resolve()}
              logoSignedUrl={evaluation?.reportHeaderLogoSignedUrl ?? ""}
              onSave={onSave}
              displayOnlyMode={reportIsCompleted}
            />
          </div>
        </FieldComponent>
      </div>
      <div key={`page-heading`} className="-mb-4">
        <FieldComponent
          key={"page-heading"}
          id={"page-heading"}
          onEditTitle={emptyFunction}
          permissions={permissions}
          title={"Page Heading"}
          placeholder="Page Heading"
        >
          <div className={reportIsCompleted ? "" : "-mt-6"}>
            <BespokeInputField
              isSaving={isPending}
              onSave={async ({ content }: { content: string }) => {
                await handleOnSave(content);
              }}
              existingContent={evaluation?.headerPageHeading ?? ""}
              placeholder="Enter Page Heading"
              inputType={"textarea"}
              displayOnlyMode={reportIsCompleted}
            />
          </div>
        </FieldComponent>
      </div>
    </SectionWidget>
  );
};
