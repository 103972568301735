import { config } from "@config/config";
import { UseQueryResult } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ApiReportSectionsAiGeneratedStatusChoices,
  GetReportByIdQuery,
  ReportType,
  ReportTypeEnums,
  useGetReportByIdQuery,
} from "@api/graphql/types-and-hooks";
import { AssessmentReportSubRoute } from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import {
  FilterSectionSubRoute,
  getFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";

interface AssessmentBuilderDataI {
  assessmentQuery: UseQueryResult<GetReportByIdQuery, unknown>;
}

export const AssessmentBuilderContext = createContext<AssessmentBuilderDataI | undefined>(undefined);

export const useAssessmentBuilderData = () => {
  const context = useContext(AssessmentBuilderContext);
  if (context === undefined) {
    throw new Error("AssessmentReportDetails must be wrap with AssessmentBuilderProvider");
  }
  return context;
};

export const AssessmentBuilderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { assessmentId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const assessmentQuery = useGetReportByIdQuery(
    {
      reportId: assessmentId || "<missing-assessment-id>",
      reportType: ReportTypeEnums.InitialAssessment,
    },
    {
      queryKey: ["get-report-by-id", assessmentId, pathname],
      refetchOnWindowFocus: false,
      refetchInterval: (query) => {
        const currentRoute =
          (pathname.split("#")?.pop()?.split("/").pop() as FilterSectionSubRoute) ||
          ("upload-files" as FilterSectionSubRoute);
        const report = query.state.data?.getReports?.edges[0]?.node as ReportType;

        const sectionsForCurrentRoute = getFilteredPageSectionsByLocation(report, currentRoute);
        const someSectionsStillPredicting = sectionsForCurrentRoute.some(
          (s) => s.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
        );
        if (someSectionsStillPredicting) {
          return config.REPORT_POLLING_INTERVAL;
        } else {
          return false;
        }
      },
    },
  );

  if (!assessmentQuery || assessmentQuery.isLoading) return null;

  const assessmentReport = assessmentQuery.data?.getReports?.edges[0]?.node;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);

  if (assessmentReport && !assessmentReport.learner?.id && currentRoute !== "client-details")
    navigate(`client-details`);

  return <AssessmentBuilderContext.Provider value={{ assessmentQuery }}>{children}</AssessmentBuilderContext.Provider>;
};
