import { FieldErrors, FieldValues, Path } from "react-hook-form";

import { LanguageMappingInterface, LanguageObjectType } from "@components/forms/interfaces";
import { LANGUAGE_KEYS } from "@internationalization/index";

const objectIsLanguageObjectType = (o: object) => "es" in o && "en" in o && Object.keys(o).length === 2;
const objectIsErrorObjectType = (o: object) =>
  "message" in o && "ref" in o && "type" in o && Object.keys(o).length === 3;

/**
 * This function does a lot, its fancy, and it is integral to making our internationalization pattern
 * for form labels work without a hitch
 *
 * It takes an incoming formKey form React Hook Form (which is typed as per our mutation parameters)
 * and it starts reducing into our language mapping by stepping into each of the sections of the formKey
 * and using them as keys to walk down into our mapping file.
 *
 * Once we have reached the end, we set the error message accordingly based on our language passed in
 * @param formKey (ie: generalDetails.birthDay, guardianDetails.guardians.0.firstName, livingConditions.individualsInHouse.2.relationship)
 * @param mapping
 * @param chosenLanguage
 * @returns
 */
export function getLabelFromFormKey<T extends FieldValues>(
  formKey: Path<T>,
  mapping: LanguageMappingInterface<T>,
  chosenLanguage: LANGUAGE_KEYS,
): string {
  if (formKey.includes(".")) {
    const errorMappingReduction = formKey.split(".").reduce(
      (acc, formKeyPortion) => {
        // if we have reduced to nothing, skip
        if (acc.obj === null) return acc;

        // if we have come across an index that is a number, move on
        if (!isNaN(Number(formKeyPortion))) return acc;

        // otherwise, let's pull off the next field from our mapping
        const resultingObject = acc.obj[formKeyPortion];

        // if we don't have a resulting object, move on
        if (!resultingObject) return acc;

        // if the resulting object we have come across is indeed our LanguageObjectType,
        // then GREAT! We found our lowest level mapping and can return the correct language
        if (objectIsLanguageObjectType(resultingObject)) {
          acc = { label: (resultingObject as LanguageObjectType)[chosenLanguage], obj: null };
        } else {
          acc = { label: "", obj: resultingObject };
        }
        return acc;
      },
      // eslint-disable-next-line
      { label: "", obj: mapping as { [key: string]: any } | null },
    );

    return errorMappingReduction.label || "<LABEL MISSING>";
  }
  return "<LABEL MISSING>";
}

/**
 * This function does a lot, its fancy, and it is integral to making our internationalization pattern
 * for form labels work without a hitch
 *
 * It takes an incoming formKey form React Hook Form (which is typed as per our mutation parameters)
 * and it starts reducing into our language mapping by stepping into each of the sections of the formKey
 * and using them as keys to walk down into our mapping file.
 *
 * Once we have reached the end, we set the error message accordingly based on our language passed in
 * @param formKey (ie: generalDetails.birthDay, guardianDetails.guardians.0.firstName, livingConditions.individualsInHouse.2.relationship)
 * @param mapping
 * @param chosenLanguage
 * @returns
 */
export function getErrorFromFormKey<T extends FieldValues>(
  formKey: Path<T>,
  formErrors: FieldErrors<T>,
  customErrorMessage?: string,
): string {
  if (formKey.includes(".")) {
    const errorMappingReduction = formKey.split(".").reduce(
      (acc, formKeyPortion) => {
        // if we have reduced to nothing, skip
        if (acc.obj === null) return acc;

        const resultingObject = acc.obj[formKeyPortion];

        // if we don't have a resulting object, move on
        if (!resultingObject) return acc;

        // if the resulting object we have come across is indeed our LanguageObjectType,
        // then GREAT! We found our lowest level mapping and can return the correct language
        if (objectIsErrorObjectType(resultingObject)) {
          acc = {
            errorMessage: customErrorMessage
              ? customErrorMessage
              : (resultingObject as FieldErrors<T>[Path<T>])?.message?.toString() || "",
            obj: null,
          };
        } else {
          acc = { errorMessage: "", obj: resultingObject };
        }
        return acc;
      },
      // eslint-disable-next-line
      { errorMessage: "", obj: formErrors as { [key: string]: any } | null },
    );

    return errorMappingReduction.errorMessage;
  } else {
    // Handle the case where form key is not nested
    const error = formErrors[formKey as string];
    if (error && typeof error === "object" && "message" in error) {
      return error.message?.toString() || "";
    }
  }
  return customErrorMessage || "";
}

function getUniqueKeys(obj1: object, obj2: object): string[] {
  if (!obj1 || !obj2) {
    return [];
  }
  const keys1 = new Set(Object.keys(obj1));
  const keys2 = new Set(Object.keys(obj2));

  const uniqueKeys = new Set([...keys1, ...keys2]);

  for (const key of keys1) {
    if (keys2.has(key)) {
      uniqueKeys.delete(key);
    }
  }

  return Array.from(uniqueKeys);
}

export interface Params<S extends object, T extends object> {
  queryData: S;
  formDefaults: T;
  keyMapping?: Partial<Record<keyof S, keyof T>>;
  valueTransformerMapping?: { [K in keyof S]?: (value: S[K]) => T[keyof T] };
  additionalValues?: { [K in keyof T]?: T[keyof T] };
}

/**
 * This function is responsible for taking a response from a query and merging it with a default set of data for a form and ensuring
 * that it matches the correct type for a form
 * @param queryData This represents the data that comes in from the GraphQL query for a form
 * @param formDefaults This represents the default data, and is also the shape of Form Mutation
 * @param valueTransformerMapping This is a mapping that converts the keys from S (data coming in) to the keys of T so data can be written correctly
 *
 * You should supply a keyMapping if there is a mismatch between the key names
 * between your data response shape (query) and your variables request shape (mutation)
 * This is sometimes the case if a mutation names a parameter one way (ie: dateOfBirth)
 * and the database model for legacy / historical reasons names the field a different way (ie: birthDate)
 *  In this case, we can't directly just stick the values from the response into the form field because the keys won't match
 *
 * You should supply a valueTransformMapping if there are values that are stored in shape in the database (ie: enums stored as all caps) that we want to show differently
 * on the form UI (lowercase names)
 * @param additionalValues Add any other additional values needed
 * @returns
 */
export function mergeObjects<S extends object, T extends object>({
  formDefaults,
  queryData,
  keyMapping,
  valueTransformerMapping,
  additionalValues,
}: Params<S, T>): T {
  const mergedObject = { ...formDefaults };
  console.debug(
    "<mergeObjects>: the following keys are not shared, consider a mapper",
    getUniqueKeys(formDefaults, queryData),
  );

  for (const key in queryData) {
    const dataKey = key as keyof S;
    const formKey = (keyMapping?.[dataKey] as keyof T) || null;
    const dataTransformer = valueTransformerMapping?.[dataKey] || null;

    if (formKey && formKey in formDefaults) {
      // We have a formKey, thus a mismatch for this key between dataObject and formDefaults
      if (queryData[dataKey] !== null && queryData[dataKey] !== undefined) {
        // We have non-null data for this key that has come from the query
        if (dataTransformer) {
          // We have a dataTransformer for this key, thus a need to do something with the data vs. return as is
          mergedObject[formKey] = dataTransformer(queryData[dataKey]);
        } else {
          // We have no dataTransformer for this key, thus pull it directly off the query data object
          mergedObject[formKey] = queryData[dataKey] as unknown as T[keyof T];
        }
      } else {
        // No actual need to do anything here since the mergedObject will already have a defaultValue
      }
    } else {
      // We don't have a formKey, thus no mismatch for this key meaning formKey (keyof T) and dataKey(keyof S) are the same, thus the type casts

      // changed because when queryData[dataKey] === false then it's not works
      if (queryData[dataKey] !== null && queryData[dataKey] !== undefined) {
        // We have non null data for this key, use it as is
        if (dataTransformer) {
          // We have a dataTransformer for this key, transform the data
          mergedObject[dataKey as unknown as keyof T] = dataTransformer(queryData[dataKey]);
        } else {
          // We have no dataTransformer for this key, pull it directly off
          mergedObject[dataKey as unknown as keyof T] = queryData[dataKey] as unknown as T[keyof T];
        }
      } else {
        // We have null data for this key from the query, thus set the field with the default object specified
        mergedObject[dataKey as unknown as keyof T] = formDefaults[
          dataKey as unknown as keyof T
        ] as unknown as T[keyof T];
      }
    }
  }
  return { ...mergedObject, ...additionalValues };
}

export interface QuestionData {
  questionKey: string;
  answer: string;
}

export const convertOnboardingQuestionListToQuestionMap = (
  data: QuestionData[] | null | undefined,
): { [key: string]: string } => {
  return (data ?? []).reduce(
    (acc, question) => {
      if (question && question.questionKey && question.answer) {
        acc[question.questionKey] = question.answer;
      }
      return acc;
    },
    {} as { [key: string]: string },
  );
};

export interface QuestionInput<T> {
  questionKey: T;
  answer: string;
  questionPageOrigin: string;
}

export const convertOnboardingQuestionMapToQuestionList = <T>(
  formData: FormData,
  questionTypePrefix: string,
  questionPageOrigin: string,
): QuestionInput<T>[] => {
  return Array.from(formData.entries())
    .filter(([name]) => name.includes(questionTypePrefix))
    .map(([name, value]) => ({
      questionKey: name.split(".").pop() as T,
      answer: value as string,
      questionPageOrigin,
    }));
};

export interface QuestionsForInput {
  questionKey: string;
  answer: string;
  questionPageOrigin: string;
}
export interface CombinedQuestionData {
  questionKey: string;
  answer: string;
  questionPageOrigin: string;
}

export const combineAnswersForCheckList = (data: QuestionsForInput[]): CombinedQuestionData[] => {
  const resultMap: { [key: string]: CombinedQuestionData } = {};

  data.forEach((item) => {
    if (resultMap[item.questionKey]) {
      // Append the current answer to the existing entry, separated by a comma
      resultMap[item.questionKey].answer += `, ${item.answer}`;
    } else {
      // Initialize the entry with the current answer and page origin
      resultMap[item.questionKey] = {
        questionKey: item.questionKey,
        answer: item.answer,
        questionPageOrigin: item.questionPageOrigin,
      };
    }
  });

  // Convert the map to an array of objects
  return Object.values(resultMap);
};

type CombinedAnswers = {
  [key: string]: string;
};

type SeparatedAnswers = {
  [key: string]: string[] | string;
};

export const commaSeparatedDataToQuestionsmap = (combinedAnswers: CombinedAnswers): SeparatedAnswers => {
  const separatedAnswers: SeparatedAnswers = {};

  for (const key in combinedAnswers) {
    if (combinedAnswers.hasOwnProperty(key)) {
      // Check if the value contains a comma
      if (combinedAnswers[key].includes(",")) {
        // Split the combined answers by comma and trim any whitespace
        const answersArray = combinedAnswers[key].split(",").map((answer) => answer.trim());
        // Assign the array of separated answers to the key
        separatedAnswers[key] = answersArray;
      } else {
        // If no comma is present, keep the value as it is
        separatedAnswers[key] = combinedAnswers[key];
      }
    }
  }

  return separatedAnswers;
};
export const convertReadableString = (str: string | undefined | null): string => {
  if (!str) return "";
  // Split the input string on underscores
  const temp = str.split("_");

  // Map over the array to capitalize each item and join them back into a string
  const join = temp
    ?.map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    })
    ?.join(" ");
  return join;
};

export const convertDBString = (str: string): string => {
  // Split the input string into an array of words
  const words = str.split(" ");

  // Convert each word to uppercase and join them with underscores
  const joined = words.map((word) => word.toUpperCase()).join("_");
  return joined;
};

interface ssnType {
  result: string;
  originalSSN: string;
}

export const maskAndFormatValue = (value: string): ssnType => {
  const originalSSN = value;
  const len = value.length; // grabs the length
  const stars = len > 0 ? (len > 1 ? (len > 2 ? (len > 3 ? (len > 4 ? "XXX-XX-" : "XXX-X") : "XXX") : "XX") : "X") : ""; // provide the masking and formatting
  const result = stars + value.substring(7); // this is the result
  return { result, originalSSN };
};

export const applyTransformationByPath = <T, U>(object: T, path: string[], transform: (value: U) => U): T => {
  let current: any | undefined = object; // eslint-disable-line @typescript-eslint/no-explicit-any

  for (let i = 0; i < path.length - 1; i++) {
    if (current === null || current === undefined) {
      return object; // Path broken, exit or throw error as needed
    }

    current = current[path[i]];
  }
  const lastKey = path[path.length - 1];
  if (current && lastKey in current) {
    current[lastKey] = transform(current[lastKey]);
  }
  return object;
};

export const transformQueryData = <T, U>(queryData: T, transformations: Record<string, (value: U) => U>): T => {
  Object.keys(transformations).forEach((pathString) => {
    const path = pathString.split(".");
    applyTransformationByPath(queryData, path, transformations[pathString]);
  });
  return queryData;
};
