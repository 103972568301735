import { RequiredFields } from "@utils/utils";
import { useEffect, useState } from "react";

import {
  Button,
  Divider,
  ExistingFileInterface,
  FileInstance,
  FileUploadAndParse,
  Select,
  SelectItem,
  Small,
} from "@fronterahealth/frontera-ui-components";

import {
  AssessmentToolType,
  LearnerFileAssessmentTemplateTypeEnums,
  ReportTypeEnums,
  useAssessmentToolsQuery,
} from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import {
  createEvaluationAssessmentCategory,
  getFileSourceByReportType,
  getMultipleFileUploadByReportType,
  getSupportedFileTypesByFileSourceType,
  groupAssessmentsByType,
} from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/Assessments/utils";
import { FileRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/UploadFiles";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/hooks";

interface AssessmentSelectionProps {
  reportDoneCallback: (bool: boolean) => void;
  existingAssessmentsTypes: string[];
  existingAssessments: (ExistingFileInterface & { assessmentTemplateType?: string })[];
}

export const AssessmentSelection: React.FC<AssessmentSelectionProps> = ({
  reportDoneCallback,
  existingAssessmentsTypes,
  existingAssessments,
}) => {
  const [selectedAssessmentCategory, setSelectedAssessmentCategory] = useState<RequiredFields<SelectItem, "id"> | null>(
    null,
  );
  const [selectedAssessmentType, setSelectedAssessmentType] = useState<RequiredFields<SelectItem, "id"> | null>(null);
  const [selectedFileType, setSelectedFileType] = useState<RequiredFields<SelectItem, "id"> | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileInstance[]>([]);

  useEffect(() => {
    if (existingAssessments?.length) {
      setSelectedAssessmentType({ primary: existingAssessments[0].fileKind, id: existingAssessments[0].fileKind });
      if (existingAssessments[0].assessmentTemplateType) {
        setSelectedFileType({
          id: existingAssessments[0].assessmentTemplateType,
          primary: convertReadableString(existingAssessments[0].assessmentTemplateType),
        });
      }
    }
  }, [existingAssessments]);

  useEffect(() => {
    if (
      fileStatuses.length > 0 &&
      fileStatuses.every((file) => file.fileStatus === "done" || file.fileStatus === "parsing")
    ) {
      reportDoneCallback(true);
    }
  }, [JSON.stringify(fileStatuses), existingAssessments]);

  const assessmentTypeLocked = !!existingAssessments?.length || fileStatuses.length > 0;
  const isDiagnosisPage = false;

  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.InitialAssessment });
  const supportedAssessmentsList: AssessmentToolType[] = response.data?.assessmentTools?.filter((item) => !!item) ?? [];
  const assessmentCategories: SelectItem[] = [];
  supportedAssessmentsList.forEach((assessment: AssessmentToolType) => {
    assessment?.categories?.map((category: string | null) => {
      let categoryObj: SelectItem;
      if (category) {
        categoryObj = createEvaluationAssessmentCategory(category);
        if (
          Object.values(assessmentCategories).findIndex((category: SelectItem) => category.id == categoryObj.id) === -1
        ) {
          assessmentCategories.push(categoryObj);
        }
      }
    });
  });

  const showUploader =
    (selectedAssessmentType && selectedFileType) || (selectedAssessmentType && existingAssessments.length);

  return (
    <div className="flex flex-col">
      {isDiagnosisPage ? (
        <Select
          title={"Category"}
          disabled={assessmentTypeLocked}
          items={assessmentCategories}
          placeholderText={"Select Assessment Category"}
          selected={selectedAssessmentCategory}
          setSelected={(item) => {
            setSelectedAssessmentCategory({
              primary: item.primary,
              id: item.id || "<missing-id>",
            });
          }}
        />
      ) : null}
      {(isDiagnosisPage && selectedAssessmentCategory) || !(isDiagnosisPage && selectedAssessmentCategory) ? (
        <Select
          title={"Assessment Type"}
          disabled={assessmentTypeLocked}
          items={supportedAssessmentsList
            .filter((item) => !existingAssessmentsTypes.includes(item?.id ?? ""))
            .filter((item) =>
              isDiagnosisPage
                ? Object.values(item.categories ?? []).findIndex((category: string | null) =>
                    category?.includes(selectedAssessmentCategory?.primary ?? ""),
                  ) != -1
                : true,
            )
            .map((supportedAssessment: AssessmentToolType) => {
              return {
                id: supportedAssessment.id,
                primary: supportedAssessment.primary,
                secondary: supportedAssessment.secondary,
              } as SelectItem;
            })}
          placeholderText={"Select Assessment Type"}
          selected={selectedAssessmentType}
          setSelected={(item) => {
            setSelectedAssessmentType({
              primary: item.primary,
              id: item.id || "<missing-id>",
              secondary: item?.secondary,
            });
            setSelectedFileType(null);
          }}
          enableSearch
        />
      ) : null}
      {selectedAssessmentType ? (
        <>
          <Select
            title={"File Source"}
            disabled={assessmentTypeLocked}
            items={getFileSourceByReportType(supportedAssessmentsList, selectedAssessmentType)}
            placeholderText={"Select File Type"}
            selected={selectedFileType}
            setSelected={(item) =>
              setSelectedFileType({
                primary: item.primary,
                id: item.id || "<missing-id>",
              })
            }
          />
          <Small displayType="normal" colorType="secondary" className="mb-4 -mt-4">
            For official scoring files, use files from the assessment provider's app or service. For Frontera Health
            templates, use files from our{" "}
            <span
              onClick={() => window.open("/downloads", "_blank")}
              className="bg-transparent text-interaction-primary cursor-pointer"
            >
              Assessment Templates
            </span>{" "}
            page.
          </Small>
        </>
      ) : null}
      {showUploader ? (
        <FileUploadAndParse
          fileKind={`${selectedAssessmentType.id}`}
          title={"Assessment File"}
          supportedFileTypes={getSupportedFileTypesByFileSourceType(
            selectedFileType?.id as LearnerFileAssessmentTemplateTypeEnums,
          )}
          maxSize={"1GB"}
          existingFiles={existingAssessments.length ? existingAssessments : undefined}
          S3BucketUrlRetriever={(params) =>
            S3BucketUrlRetriever({
              ...params,
              assessmentTemplateType: selectedFileType?.id as LearnerFileAssessmentTemplateTypeEnums,
            })
          }
          S3BucketUploadCallback={S3BucketUploadCallback}
          reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
          fileParseStatusRetriever={fileParseStatusRetriever}
          removeFileCallback={removeFileCallback}
          updateFileStatusCallback={setFileStatuses}
          singleFileUpload={!getMultipleFileUploadByReportType(supportedAssessmentsList, selectedAssessmentType)}
        />
      ) : null}
    </div>
  );
};

interface AssessmentsProps {
  existingAssessments: ExistingFileInterface[];
}
export const Assessments: React.FC<AssessmentsProps> = ({ existingAssessments }) => {
  const [numberOfAssessments, setNumberOfAssessments] = useState<number>(1);
  const [readyForNewAssessment, setReadyForNewAssessment] = useState<boolean>(false);
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.InitialAssessment });
  const supportedAssessmentsList: AssessmentToolType[] = response.data?.assessmentTools?.filter((item) => !!item) ?? [];
  const existingAssessmentsByType = groupAssessmentsByType(existingAssessments);
  const existingAssessmentsTypes = Object.keys(existingAssessmentsByType);

  useEffect(() => {
    if (existingAssessments.length) {
      setNumberOfAssessments(existingAssessmentsTypes.length);
      if (existingAssessments.every((a) => a.fileStatus === "done" || a.fileStatus === "parsing")) {
        setReadyForNewAssessment(true);
      }
    } else {
      setReadyForNewAssessment(false);
    }
  }, [existingAssessments.length]);

  const newAssessmentCount = numberOfAssessments - (existingAssessmentsTypes.length || 0);

  if (existingAssessments) {
    return (
      <FileRow title="Assessments" subtitle="Outputs of assessment" showTemplateDownloadLink={true}>
        {/* RENDER ANY EXISTING ASSESSMENTS */}
        {Object.entries(existingAssessmentsByType)
          .sort(([typeA], [typeB]) => (typeA > typeB ? 1 : -1))
          .map(([, assessments], index) => {
            return (
              <div key={index} className="flex flex-col">
                <AssessmentSelection
                  key={JSON.stringify(assessments)}
                  existingAssessments={assessments}
                  reportDoneCallback={setReadyForNewAssessment}
                  existingAssessmentsTypes={existingAssessmentsTypes}
                />
                {numberOfAssessments > 1 && index !== numberOfAssessments - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER NEWLY UPLOADED ASSESSMENTS */}
        {Array(newAssessmentCount < 0 ? 0 : newAssessmentCount)
          .fill(0)
          .map((_, index) => {
            return (
              <div key={index} className="flex flex-col">
                <AssessmentSelection
                  existingAssessments={[]}
                  reportDoneCallback={setReadyForNewAssessment}
                  existingAssessmentsTypes={existingAssessmentsTypes}
                />
                {numberOfAssessments > 1 && index !== numberOfAssessments - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER BUTTON TO ADD NEW ONES */}
        {readyForNewAssessment &&
        numberOfAssessments < supportedAssessmentsList.length &&
        numberOfAssessments === existingAssessmentsTypes.length ? (
          <Button
            className="self-end"
            appearance="link"
            text={"Add Another"}
            onClick={() => {
              setNumberOfAssessments((num) => num + 1);
            }}
          />
        ) : null}
      </FileRow>
    );
  }
  return (
    <FileRow title="Assessments" subtitle="Outputs of assessment">
      {Array(numberOfAssessments)
        .fill(0)
        .map((_, index) => {
          return (
            <div key={index} className="flex flex-col">
              <AssessmentSelection
                existingAssessments={[]}
                reportDoneCallback={setReadyForNewAssessment}
                existingAssessmentsTypes={existingAssessmentsTypes}
              />
              {numberOfAssessments > 1 && index !== numberOfAssessments - 1 ? <Divider /> : null}
            </div>
          );
        })}
      {readyForNewAssessment && numberOfAssessments < supportedAssessmentsList.length ? (
        <Button
          className="self-end"
          appearance="link"
          text={"Add Another"}
          onClick={() => {
            setNumberOfAssessments((num) => num + 1);
          }}
        />
      ) : null}
    </FileRow>
  );
};
