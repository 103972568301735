import { useParams } from "react-router-dom";

import { Small, VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

import { ApiMediaCommentsCommentTypeChoices } from "@api/graphql/types-and-hooks";
import { EmojiCommentCard } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentCard";
import { EmojiButton } from "@pages/VideoSessions/components/Utils/EmojiButton";
import { EMOJI_BUTTONS } from "@pages/VideoSessions/const";
import { EmojiButtonInterface } from "@pages/VideoSessions/types";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface EmojiCommentButtonsProps {
  onEmojiClick: (emoji: EmojiButtonInterface) => void;
  disabled: boolean;
  selectedEmoji: EmojiButtonInterface | null;
  onCommentSubmit: (comment: string, timestamp: number) => void;
  onClose: () => void;
  getCurrentTime: () => number;
  playerRef: React.RefObject<VideoPlayerHandle>;
  commentCount?: string;
}
type CommentCount = {
  comment_type: string;
  count: number;
};

export const EmojiCommentButtons: React.FC<EmojiCommentButtonsProps> = ({
  onEmojiClick,
  disabled = false,
  selectedEmoji,
  onCommentSubmit,
  onClose,
  getCurrentTime,
  playerRef,
  commentCount,
}) => {
  const { mediaId } = useParams();
  const { isUserRBT } = useVideoSessionData();
  const handleEmojiButtonClick = (emoji: EmojiButtonInterface) => {
    if (playerRef.current?.player) {
      playerRef.current.player.pause();
    }
    onEmojiClick(emoji);
  };

  const handleClose = () => {
    onClose();
    if (playerRef.current?.player) {
      playerRef.current.player.play();
    }
  };

  const handleSubmit = (comment: string, timestamp: number) => {
    onCommentSubmit(comment, timestamp);
    if (playerRef.current?.player) {
      playerRef.current.player.play();
    }
  };

  const getEmojiCount = (value: string, commentsCount: string): number => {
    const parsedCounts: CommentCount[] = JSON.parse(commentsCount);
    const matchedComment = parsedCounts.find((comment) => comment.comment_type.toLowerCase() === value.toLowerCase());
    return matchedComment ? matchedComment.count : 0;
  };
  const buttonList =
    mediaId && isUserRBT
      ? EMOJI_BUTTONS.filter((item) => item.value === ApiMediaCommentsCommentTypeChoices.Question)
      : EMOJI_BUTTONS;
  return (
    <>
      <div className="relative z-50">
        {selectedEmoji && (
          <EmojiCommentCard
            selectedEmoji={selectedEmoji}
            currentTime={getCurrentTime()}
            onSubmit={handleSubmit}
            onClose={handleClose}
            duration={playerRef.current?.player.duration()}
            className="absolute -top-40 left-1/2 transform -translate-x-1/2 z-10"
          />
        )}
        <div className="p-2 sm:p-4 flex flex-wrap gap-2 sm:gap-4 items-center justify-center">
          {buttonList.map((button) => (
            <EmojiButton
              key={button.value}
              button={button}
              onEmojiClick={handleEmojiButtonClick}
              disabled={disabled}
              count={commentCount && !mediaId ? getEmojiCount(button.value, commentCount) : null}
            />
          ))}
        </div>
        {mediaId && isUserRBT ? (
          <div className="w-full flex flex-row justify-center">
            <Small className="italic"> Questions will be sent directly to your BCBA.</Small>
          </div>
        ) : null}
      </div>
    </>
  );
};
