import { ArrowDownIcon, ArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";

import {
  ReportSectionFieldsType,
  ReportSectionsType,
  useDeleteReportSectionFieldMutation,
  useMoveReportSectionFieldUpAndDownMutation,
  useUpdateReportSectionFieldMutation,
} from "@api/graphql/types-and-hooks";
import { FieldComponent } from "@components/FieldComponent/FieldComponent";
import { notifyError } from "@components/notifications/notifications";
import { FieldBlockCardWrapper } from "@pages/AssessmentReportV2Details/Components/FieldBlockCardWrappper";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface FieldComponentWrapperProps {
  section: ReportSectionsType;
  field: ReportSectionFieldsType | undefined;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
}

export const FieldComponentWrapper: React.FC<FieldComponentWrapperProps> = ({ section, field, permissions }) => {
  const { assessmentQuery } = useAssessmentBuilderData();
  const updateFieldTitle = useUpdateReportSectionFieldMutation({});
  const deleteField = useDeleteReportSectionFieldMutation({});
  const moveReportSectionFieldUpAndDown = useMoveReportSectionFieldUpAndDownMutation({});

  const onEditTitle = async (newTitle: string) => {
    await updateFieldTitle
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        reportSectionFieldId: field?.id ?? "<missing-section-id>",
        title: newTitle,
      })
      .then((data) => {
        if (data.updateReportSectionField?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating field title : ", error);
        notifyError("Error Updating Field Title");
      });
  };

  const onDeleteField = async () => {
    await deleteField
      .mutateAsync({
        sectionId: section.id ?? "<missing-section-id>",
        fieldId: field?.id ?? "<missing-field-id>",
      })
      .then((data) => {
        if (data.deleteReportSectionField?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while deleting field : ", error);
        notifyError("Error Deleting Field");
      });
  };

  const handleMoveSectionFieldUpAndDown = async (moveUp: boolean) => {
    // moveUp = True to move up and False to move down
    await moveReportSectionFieldUpAndDown
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        fieldId: field?.id ?? "<missing-field-id>",
        moveUp: moveUp,
      })
      .then((data) => {
        if (data?.moveReportSectionFieldUpAndDown?.status) {
          assessmentQuery?.refetch();
        } else if (data.moveReportSectionFieldUpAndDown?.status === false) {
          notifyError(data.moveReportSectionFieldUpAndDown?.message ?? "Error moving field in the specified direction");
        }
      })
      .catch((error) => {
        console.error("Error moving field : ", error);
        notifyError("Error Moving Field");
      });
  };

  const actionButtonMenu = [
    // NOTE: Leaving these in for now, to be followed up soon
    // {
    //   label: "Regenerate",
    //   Icon: SparklesIcon,
    //   callback: () => console.debug("implement Regenerate"),
    // },
    {
      label: "Move Up",
      Icon: ArrowUpIcon,
      callback: () => handleMoveSectionFieldUpAndDown(true),
    },
    {
      label: "Move Down",
      Icon: ArrowDownIcon,
      callback: () => handleMoveSectionFieldUpAndDown(false),
    },
    // NOTE: Leaving these in for now, to be followed up soon
    // {
    //   label: "Move to Section",
    //   Icon: DocumentDuplicateIcon,
    //   callback: () => console.debug("implement move to section"),
    // },
    // NOTE: Leaving these in for now, to be followed up soon
    // {
    //   label: "Leave Feedback",
    //   Icon: ChatBubbleLeftIcon,
    //   callback: () => console.debug("implement Leave Feedback"),
    // },
    {
      label: "Remove Field",
      Icon: TrashIcon,
      textColorClassName: "text-red-600",
      callback: onDeleteField,
    },
  ];

  if (!field) return null;

  return (
    <FieldComponent
      key={field.id}
      id={field.id}
      onEditTitle={onEditTitle}
      actionButtonMenu={actionButtonMenu}
      permissions={permissions}
      title={field.title}
      placeholder="Enter Title"
    >
      <FieldBlockCardWrapper permissions={permissions} section={section} field={field} />
    </FieldComponent>
  );
};
