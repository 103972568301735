import React from "react";

import { Button, TabNavigation } from "@fronterahealth/frontera-ui-components";

import { useVideoSessionData } from "@providers/VideoSessionProvider";
import { videoSessionNavigationTabType } from "@providers/VideoSessionProvider";

interface VideoSideBarProps {
  currentTime: number;
}
const videoSideBarNavigationTabs: readonly videoSessionNavigationTabType[] = ["activity", "transcript", "summary"];
const tabToName = (t: string) =>
  t
    .split("-")
    .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join(" ");
export const VideoSideBar: React.FC<VideoSideBarProps> = () => {
  const { selectedTab, setSelectedTab, isUserRBT } = useVideoSessionData();

  const userTabs: readonly videoSessionNavigationTabType[] = isUserRBT ? ["activity"] : videoSideBarNavigationTabs;
  return (
    <div className={`min-w-full ${isUserRBT ? "justify-items-center border-b" : ""}  border-gray-200`}>
      <TabNavigation
        tabs={userTabs.map((tab) => ({
          current: selectedTab === tab,
          type: "component",
          name: tab,
          Component: (
            <Button
              text={<div className="w-full flex font-normal flex-row justify-center gap-2">{tabToName(tab)}</div>}
              appearance="text"
              className={`hover:text-primary ${selectedTab === tab ? "text-limestone-900" : "text-text-secondary"}`}
              onClick={() => setSelectedTab(tab)}
            />
          ),
        }))}
      />
    </div>
  );
};
