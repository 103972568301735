import { parseMarkdown, truncateText } from "@utils/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Small } from "@fronterahealth/frontera-ui-components";

import { useGetMediaSignedUrlQuery } from "@api/graphql/types-and-hooks";
import { HeaderContent } from "@pages/VideoSessions/components/HeaderContent";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { displayDate } from "@pages/VideoSessions/components/Utils/formatDate";
import { VideoContainer } from "@pages/VideoSessions/components/VideoContainer";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface MediaCardProps {
  media?: MediaCardType;
}

export const MediaCard: React.FC<MediaCardProps> = ({ media }) => {
  const navigate = useNavigate();
  const [videoDuration, setVideoDuration] = useState(0);
  const { isUserRBT, setSelectedTab } = useVideoSessionData();

  const { data, isFetching } = useGetMediaSignedUrlQuery(
    { mediaId: media?.id ?? "" },
    {
      enabled: Boolean(media?.id),
    },
  );
  const videoUrl = data?.getMediaByProviderByMediaId?.signedUploadUrl || "";
  const handleDurationChange = (duration: number) => {
    setVideoDuration(duration);
  };

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const videoContainer = document.querySelector("[data-video-player]");
    const videoElements = document.querySelectorAll(
      ".vjs-text-track-display, .vjs-big-play-button, .video-js, [data-play-button], .vjs-tech",
    );

    if (videoContainer && (videoContainer === e.target || videoContainer.contains(e.target as Node))) {
      return;
    }

    for (const element of videoElements) {
      if (element === e.target || element.contains(e.target as Node)) {
        return;
      }
    }

    const isEmojiComment = (e.target as Element).closest("[data-emoji-comment]");
    if (isEmojiComment) {
      return;
    }

    if (media) {
      navigate(`/video-sessions/${media.id}`);
    }
  };

  const handleMarkerClick = (timestamp: number, commentId: string) => {
    if (media) {
      navigate(`/video-sessions/${media.id}`, {
        state: {
          timestamp,
          commentId,
          shouldAutoPlay: false,
        },
      });
    }
  };

  if (!media || !media.therapySession) return null;

  const learnerName = media.therapySession.sessionlearnerSet?.edges[0]?.node?.sessionLearners?.learnermetadata;
  const learnerFullName = learnerName ? `${learnerName.firstName} ${learnerName.lastName}` : "";
  const providerMeta = media.therapySession.sessionproviderSet?.edges[0]?.node?.provider?.providermetadata;
  const sessionDate = media.therapySession.createdDate;
  const startDate = new Date(sessionDate);
  const formattedDate = displayDate(videoDuration, startDate);

  const sessionSummary =
    media.therapySession &&
    media.therapySession.sessionaipredictionSet &&
    media.therapySession.sessionaipredictionSet.edges.length
      ? media.therapySession.sessionaipredictionSet.edges[0]?.node?.prediction ?? ""
      : "";

  const trimmedSummary = sessionSummary ? sessionSummary?.replace(/Session Description:\s*/i, "").trim() : "";
  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="w-full h-64 flex items-center justify-center bg-limestone-50 rounded-lg">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
        </div>
      );
    }

    return (
      <>
        <HeaderContent
          learnerFullName={learnerFullName}
          providerLastName={providerMeta?.lastName ?? ""}
          providerFirstName={providerMeta?.firstName ?? ""}
          date={formattedDate}
          sessionRating={media.therapySession?.sessionRating ?? undefined}
        />
        {!isUserRBT ? (
          <div
            className="w-full rounded-t-lg sm:rounded-2xl border bg-limestone-50 border-limestone-100 px-4 max-md:px-6 py-4 sm:py-4"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("summary");
              handleCardClick(e);
            }}
          >
            <Small displayType="loud">Session Summary:</Small>
            <Small>
              <div dangerouslySetInnerHTML={{ __html: parseMarkdown(truncateText(trimmedSummary, 25)) }} />
            </Small>
          </div>
        ) : null}

        {videoUrl && (
          <VideoContainer
            videoUrl={videoUrl}
            mediaId={media.id}
            onDurationChange={handleDurationChange}
            onMarkerClick={handleMarkerClick}
            data-video-container
            commentCount={media.commentsCount}
          />
        )}
      </>
    );
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full lg:max-w-[856px] mx-auto">
        <div
          onClick={handleCardClick}
          className="w-full rounded-t-lg sm:rounded-2xl bg-white py-6 max-md:py-6 px-6 max-md:px-8 flex flex-col drop-shadow-sm gap-3 sm:gap-4 cursor-pointer hover:shadow-lg transition-shadow duration-200"
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
};
