import { Disclosure, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon, RectangleStackIcon, TagIcon, TrophyIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

// Previous interfaces remain the same
export interface GoalOverviewItem {
  goalName: string;
  goalType: string;
  description: string;
  programGoal?: string;
  masteryCriteria: string;
  medicalNecessityCriteria?: string;
  timelineEstimation: string;
  baselineData: string;
  establishBaselineOnTreatment: string;
  children?: GoalOverviewItem[];
  type: "ltg" | "stg" | "target";
}

export interface GoalOverviewProps {
  longTermGoals: GoalOverviewItem[];
  defaultOpenState?: boolean;
  hideHeaderIcon?: boolean;
  showChildrenOnly?: boolean;
}

interface LTGPanelProps {
  item: GoalOverviewItem;
  defaultOpenState?: boolean;
  hideHeaderIcon?: boolean;
}

const LTGPanel: React.FC<LTGPanelProps> = ({ item, defaultOpenState = false, hideHeaderIcon }) => {
  const [open, setOpen] = React.useState<boolean>(defaultOpenState);
  useEffect(() => {
    setOpen(defaultOpenState);
  }, [defaultOpenState]);

  const additionalDataAttributes =
    item.type === "ltg" ? { "data-ltg": true } : item.type === "stg" ? { "data-stg": true } : { "data-target": true };

  const infoDataAttributes =
    item.type === "ltg"
      ? { "data-ltg-info": true }
      : item.type === "stg"
        ? { "data-stg-info": true }
        : { "data-target-info": true };

  // Function to split goal name into prefix and name
  const splitGoalName = (goalName: string) => {
    const match = goalName.match(/^(\d+[a-z]?\.\s*)(.*)/);
    if (match && (item.type === "stg" || item.type === "target")) {
      return {
        prefix: match[1],
        name: match[2],
      };
    }
    return {
      prefix: "",
      name: goalName,
    };
  };

  const { prefix, name } = splitGoalName(item.goalName);

  return (
    <div className="relative my-2 flex w-full items-center rounded-md bg-limestone-50">
      <Disclosure defaultOpen={defaultOpenState}>
        {({}) => (
          <div className="flex w-full flex-col">
            <div className="relative flex w-full items-center gap-x-2 rounded-md bg-limestone-50 px-6 py-2">
              {hideHeaderIcon ? null : (
                <>
                  {item.type === "ltg" ? (
                    <div className="flex flex-col items-center">
                      <TrophyIcon className="h-3 w-3 text-text-secondary" />
                    </div>
                  ) : item.type === "stg" ? (
                    <div className="flex flex-col items-center">
                      <RectangleStackIcon className="h-3 w-3 text-text-secondary" />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center">
                      <TagIcon className="h-3 w-3 text-text-secondary" />
                    </div>
                  )}
                </>
              )}

              <Paragraph colorType="primary" displayType="loud" data-goal-title {...additionalDataAttributes}>
                {prefix && <span className="text-text-secondary">{prefix}</span>}
                {name}
              </Paragraph>
              <div className="absolute -left-3 h-6 w-6 ">
                {open ? (
                  <MinusIcon
                    onClick={() => setOpen((o) => !o)}
                    className={`absolute h-6 w-6 rounded-full border border-limestone-100 bg-white p-1 text-text-secondary hover:cursor-pointer hover:bg-limestone-50`}
                  />
                ) : (
                  <PlusIcon
                    onClick={() => setOpen((o) => !o)}
                    className="absolute h-6 w-6 rounded-full border border-limestone-100 bg-white p-1 text-text-secondary hover:cursor-pointer hover:bg-limestone-50"
                  />
                )}
              </div>
            </div>

            <Transition
              show={open}
              className="overflow-hidden"
              enter="transition transition-[max-height] duration-500 ease-in"
              enterFrom="transform max-h-0"
              enterTo="transform max-h-screen"
              leave="transition transition-[max-height] duration-500 ease-out"
              leaveFrom="transform max-h-screen"
              leaveTo="transform max-h-0"
            >
              <Disclosure.Panel static>
                <div className="flex flex-col gap-y-2 border-l border-limestone-50 bg-white py-4 pl-8">
                  <div>
                    <Small displayType="normal" data-goal-subtitle {...infoDataAttributes} colorType="secondary">
                      Description:{" "}
                    </Small>
                    <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                      {item.description}
                    </Paragraph>
                  </div>
                  {item.programGoal ? (
                    <div>
                      <Small displayType="normal" data-goal-subtitle {...infoDataAttributes} colorType="secondary">
                        Program Goal:{" "}
                      </Small>
                      <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                        {item.programGoal}
                      </Paragraph>
                    </div>
                  ) : null}
                  <div>
                    <Small displayType="normal" data-goal-subtitle {...infoDataAttributes} colorType="secondary">
                      Mastery Criteria
                    </Small>
                    <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                      {item.masteryCriteria}
                    </Paragraph>
                  </div>
                  {item.medicalNecessityCriteria && (
                    <div>
                      <Small displayType="normal" data-goal-subtitle {...infoDataAttributes} colorType="secondary">
                        Medical Necessity Criteria
                      </Small>
                      <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                        {item.medicalNecessityCriteria}
                      </Paragraph>
                    </div>
                  )}
                  <div>
                    <Small displayType="normal" data-goal-subtitle {...infoDataAttributes} colorType="secondary">
                      Timeline Estimation
                    </Small>
                    <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                      {item.timelineEstimation}
                    </Paragraph>
                  </div>
                  <div>
                    <Small displayType="normal" colorType="secondary" data-goal-subtitle {...infoDataAttributes}>
                      Baseline Data
                    </Small>
                    <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                      {item.baselineData}
                    </Paragraph>
                  </div>
                  <div>
                    <Small displayType="normal" colorType="secondary" data-goal-subtitle {...infoDataAttributes}>
                      Establish Baseline on Treatment
                    </Small>
                    <Paragraph displayType="normal" data-goal-description {...infoDataAttributes} colorType="primary">
                      {item.establishBaselineOnTreatment}
                    </Paragraph>
                  </div>

                  {item.children?.map((item) => {
                    return (
                      <LTGPanel
                        key={item.goalName}
                        item={item}
                        defaultOpenState={defaultOpenState}
                        hideHeaderIcon={hideHeaderIcon}
                      />
                    );
                  })}
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export const GoalOverview: React.FC<GoalOverviewProps> = ({
  longTermGoals,
  defaultOpenState,
  hideHeaderIcon = false,
  showChildrenOnly = false,
}) => {
  const renderItems = (items: GoalOverviewItem[]) => {
    return items.flatMap((item) => (showChildrenOnly && item.type === "ltg" ? item.children || [] : [item]));
  };

  return (
    <>
      {renderItems(longTermGoals).map((item) => (
        <LTGPanel key={item.goalName} item={item} defaultOpenState={defaultOpenState} hideHeaderIcon={hideHeaderIcon} />
      ))}
    </>
  );
};
