import { forwardRef, memo, useEffect } from "react";

import { VideoJsPlayerOptions, VideoPlayer, VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

interface VideoPlayerContentProps {
  videoUrl: string;
  onDurationChange?: (duration: number) => void;
}

export const VideoPlayerContent = memo(
  forwardRef<VideoPlayerHandle, VideoPlayerContentProps>(({ videoUrl, onDurationChange }, ref) => {
    useEffect(() => {
      const initializePlayer = () => {
        if (ref && "current" in ref && ref.current?.player) {
          const player = ref.current.player;

          // Check if player has loaded metadata
          if (player.readyState() >= 1) {
            const videoDuration = player.duration();
            onDurationChange?.(videoDuration);
            window.dispatchEvent(
              new CustomEvent("videoPlayerReady", {
                detail: { duration: videoDuration },
              }),
            );
          } else {
            // Add event listener for loadedmetadata if not ready
            player.one("loadedmetadata", () => {
              const videoDuration = player.duration();
              onDurationChange?.(videoDuration);
              window.dispatchEvent(
                new CustomEvent("videoPlayerReady", {
                  detail: { duration: videoDuration },
                }),
              );
            });

            // Also listen for durationchange event as sometimes duration updates after loadedmetadata
            player.on("durationchange", () => {
              const videoDuration = player.duration();
              onDurationChange?.(videoDuration);
            });
          }
        }
      };

      initializePlayer();

      // Cleanup function to remove event listeners
      return () => {
        if (ref && "current" in ref && ref.current?.player) {
          const player = ref.current.player;
          player.off("durationchange");
        }
      };
    }, [ref, videoUrl, onDurationChange]);

    const videoPlayerOptions: VideoJsPlayerOptions = {
      sources: [{ src: videoUrl, type: "video/mp4" }],
      controls: true,
      fluid: true,
      controlBar: {
        autoPlay: false,
        pictureInPictureToggle: false,
        playbackRateMenuButton: false,
        chaptersButton: false,
        descriptionsButton: false,
        subsCapsButton: false,
        audioTrackButton: false,
        children: [
          "playToggle",
          "currentTimeDisplay",
          "timeDivider",
          "durationDisplay",
          "progressControl",
          "fullscreenToggle",
          "volumePanel",
        ],
        progressControl: true,
        currentTimeDisplay: true,
        timeDivider: true,
        durationDisplay: true,
        autoHide: false,
      },
      inactivityTimeout: 0,
      userActions: {
        click: false,
        doubleClick: true,
      },
      html5: {
        preloadTextTracks: true,
      },
    };

    return (
      <div className="w-full aspect-video rounded-t-lg overflow-hidden" data-video-player="true">
        <div className="video-sessions-player w-full h-full relative">
          <VideoPlayer
            ref={ref}
            src={videoUrl}
            options={videoPlayerOptions}
            className="w-full bg-white h-full vjs-keep-controls vjs-user-active video-js vjs-default-skin"
          />
        </div>
      </div>
    );
  }),
);
