import { PropsWithChildren, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Heading, MultiSelectItem, Paragraph } from "@fronterahealth/frontera-ui-components";

import {
  AiPredictionOfEnum,
  ApiAssessmentAiReport1PredictionStatusChoices,
  AssessmentPrioritizeFieldsInput,
  useAddUpdatePrioritizeFieldsMutation,
  useGetAssessmentQuery,
} from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialog";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import { notifyError } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

const SafetyConcernOptions = [
  { id: "sib", primary: "SIB (Self Injurious Behaviors)" },
  { id: "aggression", primary: "Aggression" },
  { id: "property-destruction", primary: "Property Destruction" },
  { id: "meltdowns", primary: "Meltdowns" },
  { id: "elopement", primary: "Elopement" },
  { id: "pica", primary: "PICA" },
];
const QualityOfLifeBehaviors = [
  { id: "lack-of-toilet-training", primary: "Lack of Toilet Training" },
  { id: "sleep-challenges", primary: "Sleep Challenges" },
  { id: "disrobing", primary: "Disrobing" },
  { id: "difficulty-communicating", primary: "Difficulty Communicating" },
  { id: "low-toleration", primary: "Low Toleration" },
  { id: "inappropriate-sexual-behavior", primary: "Inappropriate Sexual Behavior" },
];

const getIdList = (items: MultiSelectItem[]): string[] => {
  return items
    .map((item) => {
      if (item.id) {
        return item.id;
      } else if (item.primary) {
        return item.primary
          .toLowerCase() // convert to lowercase
          .replace(/ /g, "-") // replace spaces with hyphens
          .replace(/[^a-z0-9-]/g, ""); // remove non-alphanumeric characters except hyphens
      } else {
        return "";
      }
    })
    .filter((id) => id !== ""); // filter out any empty strings
};

const matchOrCreateItems = (strings: string[], matchArray: MultiSelectItem[]): MultiSelectItem[] => {
  return strings.map((str) => {
    const match = matchArray.find((item) => item.id === str || item.primary.toLowerCase() === str.toLowerCase());
    if (match) {
      return match;
    } else {
      const primary = str
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return {
        id: str,
        primary: primary,
      };
    }
  });
};

interface RowProps extends PropsWithChildren {
  title: string;
  isLast?: boolean;
}
export const Row: React.FC<RowProps> = ({ title, children }) => {
  return (
    <div className="flex w-full flex-col my-8 items-end">
      <div className="grid items-start w-full grid-cols-8">
        <div className="flex flex-col col-span-3">
          <Heading type="h4">{title}</Heading>
        </div>
        <div className="col-span-5 flex flex-col bg-white border-limestone-100 border p-6 rounded-xl">{children}</div>
      </div>
    </div>
  );
};

export const Prioritize: React.FC = () => {
  const { refetch, assessmentReport } = useAssessmentBuilderData();
  const { assessmentId } = useParams();
  const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);

  const addUpdatePrioritizeMutation = useAddUpdatePrioritizeFieldsMutation({});

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", assessmentId],
      refetchOnWindowFocus: false,
    },
  );

  const type = assessmentReport.useLongTermGoals ? AiPredictionOfEnum.LongTermGoal : AiPredictionOfEnum.ShortTermGoal;
  const {
    onSubmit,
    RegisteredCheckboxList,
    formState,
    RegisteredFormRadioInput,
    RegisteredFormTextArea,
    RegisteredFormMultiSelect,
    reset,
  } = useFormUtils<AssessmentPrioritizeFieldsInput>({
    defaultValues: {
      safetyConcerns: [],
      engagementAbility: false,
      familyLife: [],
      communicationAbility: [],
      socialDesires: "",
    },
    mutationFn: async (params) => {
      const variable = {
        ...params,
        engagementAbility: params.engagementAbility === ("yes" as unknown as boolean),
      };

      await addUpdatePrioritizeMutation.mutateAsync(
        {
          assessmentId: assessmentId ? assessmentId : "",
          assessmentPrioritizeFieldsDetails: {
            ...variable,
            familyLife: variable.familyLife.length
              ? getIdList(variable.familyLife as unknown as MultiSelectItem[])
              : [],
            communicationAbility: variable.communicationAbility || [],
            safetyConcerns: variable.safetyConcerns.length
              ? getIdList(variable.safetyConcerns as unknown as MultiSelectItem[])
              : [],
          },
        },
        {
          onSuccess: async () => {
            await refetch(); // refetch assessmentBuilderProvide data
            setPromptDialogOpen(true);
          },
          onError: async (error) => {
            console.error("Error Saving Prioritize Page", error);
            notifyError("Error Saving Prioritize Page");
          },
        },
      );
    },
  });

  useEffect(() => {
    if (assessmentReport) {
      const selectedAssessmenmt = assessmentReport;

      if (selectedAssessmenmt) {
        const converted = transformQueryData(selectedAssessmenmt, {
          "assessmentPrioritizeFields.engagementAbility": (value) => (value === false || value === "no" ? "no" : "yes"),
        });

        if (converted.assessmentPrioritizeFields) {
          reset({
            ...converted.assessmentPrioritizeFields,
            safetyConcerns: converted.assessmentPrioritizeFields?.safetyConcerns
              ? // @ts-ignore: Ignoring the compiler and risking bugs because: hacking string[] here
                matchOrCreateItems(converted.assessmentPrioritizeFields.safetyConcerns, SafetyConcernOptions)
              : [],
            familyLife: converted.assessmentPrioritizeFields?.familyLife
              ? // @ts-ignore: Ignoring the compiler and risking bugs because: hacking string[] here
                matchOrCreateItems(converted.assessmentPrioritizeFields.familyLife, QualityOfLifeBehaviors)
              : [],
          } as unknown as AssessmentPrioritizeFieldsInput);
        }
      }
    }
  }, [reset, assessmentReport]);

  const aiReportPartOneStatus = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.aiReport1PredictionStatus;

  const reportNotYetKickedOff = aiReportPartOneStatus === ApiAssessmentAiReport1PredictionStatusChoices.Pending;

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Prioritization" />;
  }

  return (
    <div className="flex flex-col w-full">
      <ConfirmGenerationDialog
        type={type}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <FormContainer onSubmit={onSubmit}>
        <Row title="Safety Concerns">
          <Paragraph>Do any of the following behaviors represent a significant concern to the client</Paragraph>
          <RegisteredFormMultiSelect
            required={false}
            mode="tags"
            title={""}
            items={SafetyConcernOptions}
            formKey={"safetyConcerns"}
            formState={formState}
            placeholderText=""
            className="-mt-6"
          />
        </Row>
        <Row title="Family Life">
          <Paragraph>Are any of these behaviors significantly affecting the learner or their family</Paragraph>
          <RegisteredFormMultiSelect
            required={false}
            mode="tags"
            title={""}
            items={QualityOfLifeBehaviors}
            formKey={"familyLife"}
            formState={formState}
            placeholderText=""
            className="-mt-6"
          />
        </Row>
        <Row title="Engagement Ability">
          <Paragraph>
            Does the child independently and willingly interact with a given activity or item for at least a minute and
            tolerate others near them?
          </Paragraph>
          <RegisteredFormRadioInput
            title={""}
            items={[
              { id: "yes", title: "Yes" },
              { id: "no", title: "No" },
            ]}
            formKey={"engagementAbility"}
            formState={formState}
          />
        </Row>
        <Row title="Communication Ability">
          <Paragraph>What is the client’s current ability to communicate their wants and needs?</Paragraph>
          <RegisteredCheckboxList
            required={false}
            formState={formState}
            orientation="vertical"
            formKey="communicationAbility"
            title={""}
            items={[
              { id: "the-client-does-not-speak", title: "The client does not speak" },
              {
                id: "the-client-can-say-a-few-words-but-not-consistently",
                title: "The client can say a few words but not consistently",
              },
              {
                id: "the-client-can-communicate-their-wants-and-needs-vocally-but-struggles-to-do-so",
                title: "The client can communicate their wants and needs vocally but struggles to do so",
              },
              {
                id: "the-client-is-able-to-communicate-fluently-using-words",
                title: "The client is able to communicate fluently using words",
              },
              {
                id: "the-client-communicates-basic-wants-and-needs-using-sign-language-or-technology",
                title:
                  "The client communicates basic wants and needs using sign language or technology (e.g., a tablet)",
              },
              {
                id: "the-client-communicates-fluently-using-sign-language-or-technology",
                title: "The client communicates fluently using sign language or technology (e.g., a tablet)",
              },
            ]}
          />
        </Row>
        <Row title="Social Desires">
          <RegisteredFormTextArea
            required={false}
            formState={formState}
            formKey="socialDesires"
            label={"What is most socially significant to the family"}
          />
        </Row>
        <FooterButtonRow
          primaryButtonType="submit"
          primaryButtonDisabled={formState.isSubmitting}
          primaryButtonLoading={formState.isSubmitting}
        />
      </FormContainer>
    </div>
  );
};
