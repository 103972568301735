import { nonNullable } from "@utils/utils";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { FileInstance, Heading, Small } from "@fronterahealth/frontera-ui-components";

import {
  ApiLearnerFileFileKindChoices,
  ApiLearnerFileStatusChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  AssessmentToolType,
  ReportPageLocationEnums,
  ReportTypeEnums,
  useAssessmentToolsQuery,
} from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import { REASONS_TO_BE_OPEN, ValidationNoticeDialog } from "@components/ValidationNoticeDialog/ValidationNoticeDialog";
import { notifyError } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { Assessments } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/Assessments/Assessments";
import { ClinicalNotes } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/ClinicalNotes";
import { DiagnosisReport } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/DiagnosisReport";
import { IntakeCalls } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/IntakeCall";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface FileRowProps extends PropsWithChildren {
  title: string;
  subtitle: string;
  isLast?: boolean;
  showTemplateDownloadLink?: boolean;
}
export const FileRow: React.FC<FileRowProps> = ({ title, subtitle, showTemplateDownloadLink, children }) => {
  return (
    <div className="my-0 flex w-full flex-col items-end">
      <div className="grid w-full grid-cols-8 items-start">
        <div className="col-span-3 flex flex-col">
          <Heading type="h4">{title}</Heading>
          <Small className="max-w-56">{subtitle}</Small>
          {showTemplateDownloadLink ? (
            <Small displayType="normal" className="max-w-56 mt-4">
              For best results, use our scoring <br /> templates found{" "}
              <span
                onClick={() => window.open("/downloads", "_blank")}
                className="bg-transparent text-interaction-primary cursor-pointer"
              >
                here
              </span>
            </Small>
          ) : (
            <></>
          )}
        </div>
        <div className="col-span-5 flex flex-col rounded-xl border border-limestone-100 bg-white p-6">{children}</div>
      </div>
      {/* {isLast ? null : <Divider className="w-full" />} */}
    </div>
  );
};

export const ApiStatusToFileStatus: { [key in ApiLearnerFileStatusChoices]: FileInstance["fileStatus"] } = {
  [ApiLearnerFileStatusChoices.Uploaded]: "done",
  [ApiLearnerFileStatusChoices.Pending]: "pending",
  [ApiLearnerFileStatusChoices.ProcessError]: "parsing-failed",
  [ApiLearnerFileStatusChoices.ProcessSuccess]: "done",
  [ApiLearnerFileStatusChoices.Processing]: "parsing",
  [ApiLearnerFileStatusChoices.AiGenerating]: "ai-generating",
  [ApiLearnerFileStatusChoices.AiGeneratingError]: "ai-generation-failed",
};

export const UploadFiles: React.FC = () => {
  const { assessmentQuery } = useAssessmentBuilderData();
  const go = useNavigate();

  const files = assessmentQuery.data?.getReports?.edges?.[0]?.node?.reportFiles.edges?.map((edge) => edge?.node);
  const { refetch } = assessmentQuery;
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.InitialAssessment });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];

  const filesForAssessment = files?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);

  const existingIntakeCalls = filesForAssessment?.filter(
    (file) => file.fileKind === ApiLearnerFileFileKindChoices.IntakeCall,
  );

  const existingDiagnosis = filesForAssessment
    ?.filter((file) => file.fileKind === ApiLearnerFileFileKindChoices.DiagnosisReport)
    ?.pop();

  const existingAssessments = filesForAssessment?.filter((file) =>
    supportedAssessmentsList.map((a) => a.id).includes(file.fileKind),
  );

  const existingClinicalNotes = filesForAssessment?.filter((file) =>
    [ApiLearnerFileFileKindChoices.ClinicalNotes].includes(file.fileKind),
  );

  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);
  const [validationDialogOpen, setValidationDialogOpen] = React.useState<REASONS_TO_BE_OPEN | null>(null);

  const nextPage: FilterSectionSubRoute = "report-part-1";
  const nextPageFilteredSections = useGetFilteredPageSectionsByLocation(nextPage);

  const nextPageAlreadyGenerated = nextPageFilteredSections.some(
    (section) =>
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending ||
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
  );

  const handlePrimaryButtonAction = async () => {
    if (nextPageAlreadyGenerated) {
      go(`../${nextPage}`);
      return;
    }
    try {
      const updatedFiles = (await refetch()).data?.getReports?.edges?.[0]?.node?.reportFiles.edges?.map(
        (edge) => edge?.node,
      );

      const filesForAssessment = updatedFiles?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);

      const existingAssessments = filesForAssessment?.filter((file) =>
        supportedAssessmentsList.map((a) => a.id).includes(file.fileKind),
      );

      const parsingFiles = existingAssessments?.filter(
        (file) => file.status === ApiLearnerFileStatusChoices.Processing,
      );

      const errorFiles = existingAssessments?.filter(
        (file) => file.status === ApiLearnerFileStatusChoices.ProcessError,
      );

      if (existingAssessments?.length === 0) {
        setValidationDialogOpen("MISSING_FILES");
        return;
      }

      if (parsingFiles && parsingFiles.length > 0) {
        setValidationDialogOpen("FILES_VALIDATING");
        return;
      }

      if (errorFiles && errorFiles.length > 0) {
        setValidationDialogOpen("PARSING_FAILED");
        return;
      }
      setPromptDialogOpen(true);
    } catch (error) {
      console.error("Assessment file retrieval error: ", error);
      notifyError("Assessment file retrieval error");
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-8 ">
      <ValidationNoticeDialog reasonToBeOpen={validationDialogOpen} closeDialog={() => setValidationDialogOpen(null)} />
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.ReportPart_1}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <IntakeCalls
        existingIntakeCalls={
          existingIntakeCalls?.map((a) => ({
            fileName: a.originalFileName || "<missing-file-name>",
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind || "<missing-file-kind>",
            fileId: a.id,
          })) || []
        }
      />
      <DiagnosisReport
        existingDiagnosis={
          existingDiagnosis
            ? {
                fileName: existingDiagnosis.originalFileName || "<missing-file-name>",
                fileSize: existingDiagnosis.fileSize,
                fileStatus: ApiStatusToFileStatus[existingDiagnosis.status || ApiLearnerFileStatusChoices.Pending],
                metadata: existingDiagnosis.metadata,
                fileKind: existingDiagnosis.fileKind || "<missing-file-kind>",
                fileId: existingDiagnosis.id,
              }
            : undefined
        }
      />
      <Assessments
        existingAssessments={
          existingAssessments?.map((a) => ({
            fileName: a.originalFileName,
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind,
            fileId: a.id,
            assessmentTemplateType: a.assessmentTemplateType,
          })) || []
        }
      />
      <ClinicalNotes
        existingClinicalNotes={
          existingClinicalNotes?.map((a) => ({
            fileName: a.originalFileName,
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind,
            fileId: a.id,
          })) || []
        }
      />
      <FooterButtonRow
        primaryButtonTitle="Next"
        primaryButtonDisabled={assessmentQuery.isFetching}
        primaryButtonAction={handlePrimaryButtonAction}
        skipNavigate
      />
    </div>
  );
};
