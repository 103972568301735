import {
  AI_GENERATED,
  Button,
  Dialog,
  Folder,
  FolderProps,
  GoalsTabProps,
  SelectItem,
} from "@fronterahealth/frontera-ui-components";

import { Goals } from "@components/GoalsBank/constants";

export const getTabsToDisplay = (
  selectedTab: Goals,
  setSelectedTab: (goal: Goals) => void,
  goalTypesToDisplay: Goals[],
): GoalsTabProps[] => {
  return Object.values(goalTypesToDisplay).map((goal) => {
    return {
      tab: {
        type: "component",
        current: selectedTab == goal,
        name: goal.valueOf(),
        Component: (
          <Button
            key={goal}
            onClick={() => {
              setSelectedTab(goal);
            }}
            textClassName="hover:text-limestone-900 text-limestone-600 active:text-limestone-900"
            appearance="text"
            text={goal.valueOf()}
          ></Button>
        ),
      },
    };
  });
};

export const getFolderSelections = (userFoldersSection: FolderProps, orgFoldersSection: FolderProps) => {
  return userFoldersSection.folders
    .map((folder: Folder) => {
      return { id: folder.id, primary: folder.name + " (Personal)" };
    })
    .concat(
      orgFoldersSection.folders.map((folder: Folder) => {
        return { id: folder.id, primary: folder.name + " (Organizational)" };
      }),
    );
};

export const getUserFolderSection = (folders: Folder[]): FolderProps => {
  return {
    folderSectionName: "My Folders",
    folders,
  };
};

export const getOrgFolderSection = (folders: Folder[], orgName: string): FolderProps => {
  return {
    folderSectionName: orgName + "'s Folders",
    folders,
  };
};

export const getAiGeneratedFolderSection = (): FolderProps => {
  return {
    folderSectionName: AI_GENERATED,
    folders: [{ name: "Suggested", id: AI_GENERATED, type: AI_GENERATED } as Folder],
  };
};

export const findFolder = (folderId: string, folders: Folder[]): Folder => {
  return folders.find((folder) => folder.id === folderId) ?? { id: "", name: "", type: "" };
};

export const findFolderSelection = (folderId: string, folderSelection: SelectItem[]): SelectItem => {
  return folderSelection.find((folderSelect) => folderId === folderSelect.id) ?? { id: "", primary: "" };
};

interface RenderGoalBankFormDialogProps {
  title: string;
  openForm: boolean;
  setOpenForm: (open: boolean) => void;
  onSubmit: () => void;
  children: React.ReactNode;
}

export const renderGoalBankFormDialog: React.FC<RenderGoalBankFormDialogProps> = ({
  title,
  openForm,
  setOpenForm,
  onSubmit,
  children,
}) => {
  return (
    <Dialog
      title={title}
      size="2xl"
      overflowBehavior="yAuto"
      open={openForm}
      setOpen={setOpenForm}
      primaryButton={{
        text: "Save",
        onClick: onSubmit,
        type: "submit",
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: () => setOpenForm(false),
      }}
      bodyClassName="-mx-4"
    >
      <div className="px-4">{children}</div>
    </Dialog>
  );
};
