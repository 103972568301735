import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { getHierarchicalIdentifier, isMissingValue } from "@utils/utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Badge, Button, Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import {
  AiReportPredictionOfEnum,
  ApiAiSuggestedTargetsTimelineEstimationTypeChoices,
  ApiAssessmentAiReport2PredictionStatusChoices,
  ApiLongTermGoalGoalTypeChoices,
  ApiLongTermGoalTimelineEstimationTypeChoices,
  ApiShortTermGoalTimelineEstimationTypeChoices,
  ApiTargetTimelineEstimationTypeChoices,
  AssessmentReportFieldsEnums,
  GetLongTermShortTermGoalsQuery,
  useAssessmentReportFieldsQuery,
  useGetAssessmentQuery,
  useGetLongTermShortTermGoalsQuery,
} from "@api/graphql/types-and-hooks";
import { useGoalCategoriesQuery } from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { GenerationErrorPage } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/GenerationErrorPage";
import { SmartFieldWrapper } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/SmartFieldWrapper";
import {
  getFieldsForSection,
  getIconForSection,
  isLongTermGoalTypeSection,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/assessmentFieldEnumMapping";
import {
  HeadingSection,
  StickyNav,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import {
  GoalOverview,
  GoalOverviewItem,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/GoalOverview";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

const convertNullToPlaceholder = (
  val1: number | null | undefined,
  val2:
    | ApiTargetTimelineEstimationTypeChoices
    | ApiLongTermGoalTimelineEstimationTypeChoices
    | ApiAiSuggestedTargetsTimelineEstimationTypeChoices
    | ApiShortTermGoalTimelineEstimationTypeChoices
    | null
    | undefined,
  placeholder: string,
): string => {
  if (val1 && val2) {
    return `${val1} ${convertReadableString(val2)}`;
  } else if (val1) {
    return `${val1}`;
  } else if (val2) {
    return `${convertReadableString(val2)}`;
  } else {
    return placeholder;
  }
};

export const LTG_STG_Targets_To_Overview = (data: GetLongTermShortTermGoalsQuery | undefined): GoalOverviewItem[] => {
  return (
    data?.getAssessments?.edges?.[0]?.node?.longtermgoalSet?.edges
      ?.map((edge) => edge?.node)
      ?.map((ltg, ltgIndex) => {
        return {
          description: isMissingValue(ltg?.description),
          goalName: isMissingValue(`${getHierarchicalIdentifier(ltgIndex)}${ltg?.goalName}`),
          goalType: isMissingValue(ltg?.goalType),
          masteryCriteria: isMissingValue(ltg?.masteryCriteria),
          timelineEstimation: convertNullToPlaceholder(ltg?.timelineEstimationValue, ltg?.timelineEstimationType, "-"),
          baselineData: isMissingValue(ltg?.baselineData),
          establishBaselineOnTreatment: ltg?.establishBaselineOnTreatment ? "Yes" : "No",
          programGoal: isMissingValue(ltg?.programGoal),
          type: "ltg",
          children: ltg?.shorttermgoalSet?.edges
            ?.map((edge) => edge?.node)
            ?.map((stg, stgIndex) => {
              const targets: GoalOverviewItem[] =
                stg?.targetSet?.edges
                  ?.map((edge) => edge?.node)
                  ?.map((target, targetIndex) => {
                    return {
                      description: isMissingValue(target?.targetDescription),
                      goalName: isMissingValue(
                        `${getHierarchicalIdentifier(ltgIndex, stgIndex, targetIndex)}${target?.targetName}`,
                      ),
                      goalType: isMissingValue(ltg?.goalType),
                      masteryCriteria: isMissingValue(target?.masteryCriteria),
                      baselineData: isMissingValue(target?.baselineData),
                      establishBaselineOnTreatment: target?.establishBaselineOnTreatment ? "Yes" : "No",
                      timelineEstimation: convertNullToPlaceholder(
                        target?.timelineEstimationValue,
                        target?.timelineEstimationType,
                        "-",
                      ),
                      type: "target",
                    };
                  }) || [];
              return {
                description: isMissingValue(stg?.description),
                goalName: isMissingValue(`${getHierarchicalIdentifier(ltgIndex, stgIndex)}${stg?.goalName}`),
                goalType: isMissingValue(ltg?.goalType),
                masteryCriteria: isMissingValue(stg?.masteryCriteria),
                medicalNecessityCriteria: stg?.hasMedicalNecessity
                  ? isMissingValue(stg?.medicalNecessityCriteria)
                  : undefined,
                timelineEstimation: convertNullToPlaceholder(
                  stg?.timelineEstimationValue,
                  stg?.timelineEstimationType,
                  "-",
                ),
                programGoal: isMissingValue(stg?.programGoal),
                type: "stg",
                baselineData: isMissingValue(stg?.baselineData),
                establishBaselineOnTreatment: stg?.establishBaselineOnTreatment ? "Yes" : "No",
                children: targets,
              };
            }),
        };
      }) || []
  );
};

const baseHeadings = [
  "Coordination of Care",
  "Program Recommendations",
  "Service Reduction and Termination",
  "Risk Evaluation & Emergency Plan",
  "Program Contacts for Clients",
] as const;

const longTermGoalHeadings = [
  "Behavior Reduction Goals",
  "Skill Acquisition Goals",
  "Parent / Caregiver Goals",
] as const;

type ReportSummaryPart2Props = {
  isDisplayModeForReview: boolean;
};

export const ReportSummaryPart2: React.FC<ReportSummaryPart2Props> = ({ isDisplayModeForReview }) => {
  const { assessmentId } = useParams();
  const go = useNavigate();
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";
  const { user } = useAuth0();

  const { data: categoryData } = useGoalCategoriesQuery({ organizationId: user?.org_id }, { enabled: !!user?.org_id });
  const categoryNames = categoryData?.goalCategories?.edges.map((edge) => edge?.node?.name);

  const useLongTermGoals = assessmentReport.useLongTermGoals;
  const assessmentReportHeadings = useLongTermGoals
    ? [...longTermGoalHeadings, ...baseHeadings]
    : [...(categoryNames || []), ...baseHeadings];

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    { assessmentId: assessmentId || "<missing-assessment-id>" },
    {
      queryKey: ["get-assessment", assessmentId],
      refetchOnWindowFocus: false,
      refetchInterval: (query) => {
        const status = query.state.data?.getAssessments?.edges?.[0]?.node?.aiReport2PredictionStatus;
        return status === ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPending
          ? config.REPORT_POLLING_INTERVAL
          : false;
      },
    },
  );

  const aiReportPart2PredictionStatus =
    assessmentQueryData?.getAssessments?.edges?.[0]?.node?.aiReport2PredictionStatus;

  const reportStillGenerating =
    !isDisplayModeForReview &&
    aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPending;
  const reportDone =
    aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPredicted;
  const reportNotYetKickedOff =
    !isDisplayModeForReview && aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.Pending;
  const reportGenerationFailed =
    aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.Failure;
  const reportGenerationFailureReason = assessmentQueryData?.getAssessments?.edges?.[0]?.node?.failureReason;

  const { data, refetch } = useAssessmentReportFieldsQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
      learnerId,
    },
    {
      refetchOnMount: reportStillGenerating ? false : "always",
      queryKey: ["assessment-report-fields-query-page-2", assessmentId, learnerId, reportDone],
      enabled: !!assessmentId && !!learnerId && reportDone,
    },
  );

  const { data: longTermShortTermTargets } = useGetLongTermShortTermGoalsQuery(
    { assessmentId: assessmentId || "<missing-assessmentId>" },
    {
      queryKey: ["get-long-term-short-term-goals-query", learnerId, reportDone],
      enabled: !!assessmentId && !!learnerId && reportDone,
      retry: false,
      refetchOnMount: reportStillGenerating ? false : "always",
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const abFields = data?.assessmentReportFields?.edges?.map((edge) => edge?.node) || [];

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Report Part 2" />;
  }

  if (reportStillGenerating) {
    return (
      <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
        <SparklesIcon className="h-12 w-12 text-limestone-200" />
        <Heading type="h4">Good news! Your Assessment Report (Part 2) is underway</Heading>
        <Paragraph displayType="normal" colorType="secondary" className="text-center">
          This could take some time to complete. <br />
          You may leave this page and continue working and come back soon
        </Paragraph>
        <ul className="flex flex-col gap-y-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center justify-end">
              <Small displayType="loud">Report Part Two Generation: </Small>
            </div>
            <div className="flex items-center justify-start">
              {aiReportPart2PredictionStatus ===
              ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPending ? (
                <Badge dot text="Generating" appearance="ai" />
              ) : aiReportPart2PredictionStatus ===
                ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPredicted ? (
                <Badge text="Done" appearance="success" />
              ) : null}
            </div>
          </div>
        </ul>
        <Button appearance="primary" text="Return to Assessments" onClick={() => go("/assessment-reports")} />
      </div>
    );
  }

  if (reportGenerationFailed && !isDisplayModeForReview) {
    return (
      <GenerationErrorPage
        page={AiReportPredictionOfEnum.AssessmentReport_2}
        errorMessage={reportGenerationFailureReason}
      />
    );
  }

  const renderSectionContent = (section: string, index: number) => {
    const idFromSection = section.toLowerCase().split(" ").join("-");
    const fieldsForSection = getFieldsForSection(section);
    const iconForSection = getIconForSection(section);
    const goalItems = LTG_STG_Targets_To_Overview(longTermShortTermTargets);

    if (isLongTermGoalTypeSection(section) && useLongTermGoals) {
      const goalTypeMapping = {
        "Behavior Reduction Goals": ApiLongTermGoalGoalTypeChoices.BehaviorReduction,
        "Skill Acquisition Goals": ApiLongTermGoalGoalTypeChoices.SkillAcquisition,
        "Parent / Caregiver Goals": ApiLongTermGoalGoalTypeChoices.ParentCaregiverInvolvement,
      };

      const summaryFieldMapping = {
        "Behavior Reduction Goals": AssessmentReportFieldsEnums.BehaviorReductionSummary,
        "Skill Acquisition Goals": AssessmentReportFieldsEnums.SkillAcquisitionSummary,
        "Parent / Caregiver Goals": AssessmentReportFieldsEnums.ParentCaregiverSummary,
      };

      const existingContent = abFields?.find(
        (node) => node?.fieldKey === summaryFieldMapping[section as keyof typeof summaryFieldMapping],
      );

      return (
        <div className="pageBreak" key={idFromSection}>
          <HeadingSection
            key={idFromSection}
            Icon={iconForSection}
            id={idFromSection}
            label={section}
            isLast={index === assessmentReportHeadings.length - 1}
          >
            <div className="flex flex-col">
              <SmartFieldWrapper
                field={summaryFieldMapping[section as keyof typeof summaryFieldMapping]}
                section={section}
                existingContent={existingContent?.value}
                existingId={existingContent?.id || "<missing-id>"}
                isDisplayMode={isDisplayModeForReview}
              />
              <div className="goals">
                <GoalOverview
                  defaultOpenState={isDisplayModeForReview}
                  longTermGoals={goalItems.filter(
                    (ltg) => ltg.goalType === goalTypeMapping[section as keyof typeof goalTypeMapping],
                  )}
                  hideHeaderIcon
                />
              </div>
            </div>
          </HeadingSection>
        </div>
      );
    }

    // Apply filtering only for non-LTG sections when useLongTermGoals is false
    if (!useLongTermGoals) {
      const filteredGoals = goalItems.filter(
        (goalItem) =>
          goalItem.goalName.split(".").at(1)?.trim() === section && goalItem.children && goalItem.children.length > 0,
      );

      // Skip rendering this section if there are no goals with children
      if (fieldsForSection.length === 0 && filteredGoals.length === 0) {
        return null;
      }

      return (
        <div className="pageBreak" key={idFromSection}>
          <HeadingSection
            Icon={iconForSection}
            key={idFromSection}
            id={idFromSection}
            label={section}
            isLast={index === assessmentReportHeadings.length - 1}
          >
            {fieldsForSection.map((field) => {
              const existingContent = abFields?.find((node) => node?.fieldKey === field);
              return (
                <SmartFieldWrapper
                  key={`${section}-${field}`}
                  field={field}
                  section={section}
                  existingContent={existingContent?.value}
                  isDisplayMode={isDisplayModeForReview}
                  existingId={existingContent?.id}
                />
              );
            })}
            {filteredGoals.length > 0 && (
              <GoalOverview showChildrenOnly={true} hideHeaderIcon={true} longTermGoals={filteredGoals} />
            )}
          </HeadingSection>
        </div>
      );
    }

    // Regular rendering for all other cases
    return (
      <div className="pageBreak" key={idFromSection}>
        <HeadingSection
          Icon={iconForSection}
          key={idFromSection}
          id={idFromSection}
          label={section}
          isLast={index === assessmentReportHeadings.length - 1}
        >
          {fieldsForSection.map((field) => {
            const existingContent = abFields?.find((node) => node?.fieldKey === field);
            return (
              <SmartFieldWrapper
                key={`${section}-${field}`}
                field={field}
                section={section}
                existingContent={existingContent?.value}
                isDisplayMode={isDisplayModeForReview}
                existingId={existingContent?.id}
              />
            );
          })}
        </HeadingSection>
      </div>
    );
  };

  return (
    <div className={`grid grid-cols-4 gap-x-1 ${isDisplayModeForReview ? "pb-0" : "pb-20"}`}>
      <div className={`${isDisplayModeForReview ? "col-span-4" : "col-span-3"} flex w-full flex-col gap-y-4`}>
        {assessmentReportHeadings.map((section, index) => renderSectionContent(section ?? "", index)).filter(Boolean)}
      </div>
      {!isDisplayModeForReview && (
        <div className="flex w-full flex-col items-end">
          <StickyNav state={{}} headingType="h4" />
        </div>
      )}
      {!isDisplayModeForReview && <FooterButtonRow />}
    </div>
  );
};
