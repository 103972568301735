import { config } from "@config/config";
import { PlusIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Badge, Button, Divider, Heading, Paragraph, Small, Tooltip } from "@fronterahealth/frontera-ui-components";

import {
  AiPredictionOfEnum,
  AiSuggestedLongTermGoalsTypeEdge,
  ApiAssessmentAiLtgPredictionStatusChoices,
  LongTermGoalType,
  ReportPageLocationEnums,
  ReportTypeEnums,
  useGetAssessmentQuery,
  useReportLongTermGoalsQuery,
} from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import { AddLongTermGoalsBank } from "@components/GoalsBank/AddLongTermGoalsBank/AddLongTermGoalsBank";
import { LongTermGoalCard } from "@components/LongTermGoalCard/LongTermGoalCard";
import { NoDataCard } from "@components/NoDataCard/NoDataCard";
import { WarningCard } from "@components/WarningCard/WarningCard";
import { notifyError } from "@components/notifications/notifications";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { GenerationErrorPage } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/GenerationErrorPage";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export const LongTermGoals: React.FC = () => {
  const { assessmentId: reportId } = useParams();
  const go = useNavigate();
  const { assessmentQuery } = useAssessmentBuilderData();
  const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;
  const learnerId = assessmentReport?.learner?.id || null;
  const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
  isPanelOpen;
  const [warnUser, setWarnUser] = useState<boolean>(false);
  const [selectedLTG, setSelectedLTG] = useState<LongTermGoalType | undefined>(undefined);
  selectedLTG;
  const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    {
      assessmentId: reportId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", reportId],
      refetchOnWindowFocus: false,
      refetchInterval: (query) => {
        const aiLtgPredictionStatus = query.state.data?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
          ?.aiLtgPredictionStatus;
        if (aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.GoalPredictionPending) {
          return config.REPORT_POLLING_INTERVAL;
        } else {
          return false;
        }
      },
    },
  );

  const aiLtgPredictionStatus = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.aiLtgPredictionStatus;

  const reportStillGenerating =
    aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.GoalPredictionPending;

  const reportNotYetKickedOff = aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.Pending;

  const reportDone = aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.GoalPredictionPredicted;

  const reportGenerationFailed = aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.Failure;
  const reportGenerationFailureReason = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.failureReason;

  const reportLongTermGoalsQuery = useReportLongTermGoalsQuery(
    { id: reportId ? reportId : "<missing-report-id>", reportType: ReportTypeEnums.InitialAssessment },
    {
      queryKey: ["report-long-term-goals-query", learnerId, reportId, reportDone],
      enabled: !!reportId && !!learnerId && reportDone,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: reportStillGenerating ? false : "always",
    },
  );

  const { data, error, refetch } = reportLongTermGoalsQuery;

  useEffect(() => {
    if (error) {
      console.error("Error while fetching LTG list", error);
      notifyError("Error fetching long term goals");
    }
  }, [error]);

  const goalList = data?.getReports?.edges?.[0]?.node?.reportTreatmentPlan?.longtermgoalSet?.edges || [];
  const aiGoalList = data?.getReports?.edges?.[0]?.node?.reportTreatmentPlan?.aisuggestedlongtermgoalsSet?.edges || [];

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Long Term Goals" />;
  }

  if (reportStillGenerating) {
    return (
      <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
        <SparklesIcon className="h-12 w-12 text-limestone-200" />
        <Heading type="h4">Good news! Your Treatment Plan is underway</Heading>
        <Paragraph displayType="normal" colorType="secondary" className="text-center">
          This could take some time to complete. <br />
          You may leave this page and continue working and come back soon
        </Paragraph>
        <ul className="flex flex-col gap-y-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center justify-end">
              <Small displayType="loud">Long Term Goal Generation: </Small>
            </div>
            <div className="flex items-center justify-start">
              {aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices["GoalPredictionPending"] ? (
                <Badge dot text={"Generating"} appearance={"ai"} />
              ) : aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices["GoalPredictionPredicted"] ? (
                <Badge text={"Done"} appearance={"success"} />
              ) : null}
            </div>
          </div>
        </ul>
        <Button
          appearance="primary"
          text="Return to Assessments"
          onClick={() => {
            go("/assessment-reports");
          }}
        />
      </div>
    );
  }

  if (reportGenerationFailed) {
    return <GenerationErrorPage page={AiPredictionOfEnum.LongTermGoal} errorMessage={reportGenerationFailureReason} />;
  }

  return (
    <>
      <ConfirmGenerationDialog
        // TODO BACKPORT: Will need a way to either namespace or abstract these so that based on a report type (perhaps an additoinally passed param to this dialog component)
        // there is only a subset of reportPageTypes that can be passed in (and that will be confiugred per report type)
        type={ReportPageLocationEnums.ReportPart_1}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <div className="h-full">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            <Heading type="h3">Long Term Goals</Heading>
            <Tooltip
              text="Long Term Goals are Overarching objectives that guide the direction of therapeutic interventions over an
      extended period. They articulate the main developmental or behavioral change desired as an outcome of
      the intervention."
            ></Tooltip>
          </div>
          <div className="flex flex-row gap-2 justify-items-center items-center">
            <AddLongTermGoalsBank
              learnerId={learnerId ?? "<missing-learnerId>"}
              onAdd={refetch}
              assessmentId={reportId ?? "<missing-assessmentId>"}
              aiGoals={aiGoalList as AiSuggestedLongTermGoalsTypeEdge[]}
            />

            <Button
              text={
                <Small className={"flex items-center px-2 text-white"}>
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Custom Goal
                </Small>
              }
              buttonAction="action"
              appearance="primary"
              className=" border-secondary"
              onClick={() => {
                setSelectedLTG(undefined);
                setPanelOpen(true);
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          {goalList.length === 0 ? (
            <NoDataCard
              title="You have no long term goals added"
              description={
                <span>
                  Long term goal that you add will end up here. <br />
                  Add a long term goal from our suggestions, or create one of your own to get started
                </span>
              }
            >
              {warnUser && <WarningCard content="You must have at least one long term goal in your treatment plan" />}
            </NoDataCard>
          ) : (
            <>
              <div className="flex w-full items-center gap-x-4">
                <Small displayType="loud">Selected</Small>
                <Divider borderClassName="border-limestone-100" className="w-full" />
              </div>
              <div className="mt-2 grid snap-y grid-cols-3 gap-4">
                {goalList.map((record) => (
                  <LongTermGoalCard
                    key={record?.node?.id}
                    onClick={() => {
                      setSelectedLTG(record?.node as LongTermGoalType);
                      setPanelOpen(true);
                    }}
                    refetchGoals={async () => {
                      await refetch();
                    }}
                    goalObject={record?.node as LongTermGoalType}
                  />
                ))}
              </div>
            </>
          )}

          {/* TODO: BACKPORT: Need to bring this back */}
          {/* <LongTermGoalPanel
            title={selectedLTG ? "Edit Long Term Goal" : "Add Long Term Goal"}
            isPanelOpen={isPanelOpen}
            setPanelOpen={setPanelOpen}
            selectedGoal={selectedLTG}
            refetchLongTermGoalsList={refetch}
          /> */}
        </div>

        <FooterButtonRow
          primaryButtonAction={() => {
            return new Promise<void>((resolve) => {
              if (!goalList.length) {
                setWarnUser(true);
                resolve();
              } else {
                setWarnUser(false);
                setPromptDialogOpen(true);
                resolve();
              }
            });
          }}
          skipNavigate
        />
      </div>
    </>
  );
};
