import { useEffect, useRef, useState } from "react";

import { Paragraph } from "@fronterahealth/frontera-ui-components";

import { EmojiMarker } from "@pages/VideoSessions/types";

interface EmojiCommentPanelProps {
  emojiMarkers: EmojiMarker[];
  isLoading?: boolean;
  mediaId: string;
  onMarkerClick: (time: number, commentId: string) => void;
}

export const EmojiCommentPanel: React.FC<EmojiCommentPanelProps> = ({
  emojiMarkers,
  isLoading = false,
  onMarkerClick,
}) => {
  const [hoveredMarkerId, setHoveredMarkerId] = useState<string | null>(null);
  const [horizontalPosition, setHorizontalPosition] = useState("right");
  const tooltipRef = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (hoveredMarkerId && tooltipRef.current[hoveredMarkerId]) {
      const tooltip = tooltipRef.current[hoveredMarkerId];
      if (tooltip) {
        const tooltipRectangle = tooltip.getBoundingClientRect();

        // Calculate horizontal position
        const tooltipWidth = tooltip.offsetWidth;
        const spaceLeft = tooltipRectangle.left;
        const spaceRight = window.innerWidth - tooltipRectangle.right;

        let horizontal = "center";
        if (spaceLeft >= tooltipWidth && spaceLeft >= spaceRight) {
          horizontal = "left";
        } else if (spaceRight >= tooltipWidth && spaceRight >= spaceLeft) {
          horizontal = "right";
        }
        setHorizontalPosition(horizontal);
      }
    } else {
      setHorizontalPosition("center");
    }
  }, [hoveredMarkerId]);

  return (
    <div className="flex items-center h-12 bg-black text-white rounded-b-lg">
      <div className="w-[10px] h-10 flex-none"></div>
      <div className="flex-1 relative h-full">
        <div className="px-[10px] w-full h-full">
          {!isLoading ? (
            <div className={`w-full h-1 mt-6 relative ${isLoading ? "opacity-0" : ""}`}>
              {emojiMarkers.map((marker: EmojiMarker) => (
                <div
                  key={marker.commentId}
                  className="absolute -top-4 transform -translate-x-1/2 group cursor-pointer"
                  style={{ left: `${marker.position}%` }}
                  onMouseEnter={() => setHoveredMarkerId(marker.commentId)}
                  onMouseLeave={() => setHoveredMarkerId(null)}
                  onClick={() => onMarkerClick(marker.time, marker.commentId)}
                >
                  <div className="relative">
                    <span className={`text-sm ${marker.commentId === hoveredMarkerId ? "opacity-50" : ""}`}>
                      {marker.emoji}
                    </span>
                    {hoveredMarkerId === marker.commentId && (
                      <div
                        ref={(el) => (tooltipRef.current[marker.commentId] = el)}
                        className={`absolute bg-white shadow-lg rounded-lg p-3 w-[200px] z-50 border border-gray-200 cursor-pointer bottom-8 ${
                          horizontalPosition === "left"
                            ? "right-0"
                            : horizontalPosition === "right"
                              ? "left-0"
                              : "translate-x-[-50%]"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onMarkerClick(marker.time, marker.commentId);
                        }}
                      >
                        <div className="flex flex-col gap-1">
                          <Paragraph colorType="secondary" className="text-sm">
                            {marker.authorFirstName} {marker.authorLastName}
                          </Paragraph>
                          <Paragraph colorType="primary" className="text-sm truncate overflow-hidden">
                            {marker.content}
                          </Paragraph>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-[10px] h-10 flex-none"></div>
    </div>
  );
};
