import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, Select, SelectItem, Small } from "@fronterahealth/frontera-ui-components";

import {
  ReportTypeEnums,
  useCreateReportMutation,
  useGetProviderLearnersQuery,
  useGetReportsQuery,
} from "@api/graphql/types-and-hooks";
import { AssessmentReport } from "@components/AssessmentReportsTable/AssessmentReportsTable";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

interface NewAssessmentDialogProps {
  setDialogOpen: (open: boolean) => void;
  isDialogOpen: boolean;
  credits: number;
  isSelfServeUser: boolean;
}

export const NewAssessmentDialog: React.FC<NewAssessmentDialogProps> = ({
  isDialogOpen,
  setDialogOpen,
  credits,
  isSelfServeUser,
}) => {
  const [selected, setSelected] = useState<SelectItem | undefined>();
  const go = useNavigate();
  const reportType = ReportTypeEnums.InitialAssessment;

  useEffect(() => {
    setSelected(undefined);
  }, [isDialogOpen]);

  const learnerQuery = useGetProviderLearnersQuery(
    {},
    {
      queryKey: ["get-provider-learners"],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const getLearnerAssessment = useGetReportsQuery(
    {
      learnerId: selected?.id,
      reportType,
    },
    {
      queryKey: ["get-learner-assessment", selected?.id],
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const createLearnerAssessmentMutation = useCreateReportMutation({});

  const { data, isLoading } = learnerQuery;
  const { data: learnerAssessmentData, isLoading: learnerAssessmentLoading } = getLearnerAssessment;
  const { data: createAssessmentData, isPending, error } = createLearnerAssessmentMutation;

  const itemsData = useMemo(() => {
    const items: SelectItem[] = [];

    if (data?.getProviderLearners) {
      const learnerItems = data.getProviderLearners?.edges
        .map((learner) => ({
          primary: `${learner?.node?.learnermetadata?.firstName} ${learner?.node?.learnermetadata?.lastName}`,
          id: learner?.node?.id,
        }))
        .sort((la, lb) => (la.primary > lb.primary ? 1 : -1));
      return learnerItems;
    }

    return items;
  }, [data]);

  const createAssessmentObject = async () => {
    await createLearnerAssessmentMutation.mutateAsync({
      reportType,
      learnerId: selected?.id,
    });
  };

  useEffect(() => {
    if (error) {
      console.error("Error while creating assessment", error);
      notifyError("Error creating assessment");
    }
  }, [error]);

  useEffect(() => {
    if (!createAssessmentData) return;

    const assessment = createAssessmentData.createReport;
    const assessmentStatus = assessment?.status;

    if (assessmentStatus) {
      notifySuccess(assessment?.message || "Assessment created successfully");

      const learnerAssessment = assessment?.report;

      if (learnerAssessment) {
        const { clientFirstName, clientLastName } = learnerAssessment?.reportClientDetails || {};

        setDialogOpen(false);

        go(`/assessment-reports-v2/${learnerAssessment?.id}`, {
          state: {
            id: learnerAssessment?.id,
            clientName: `${clientFirstName || "N/A"} ${clientLastName || ""}`,
            lastUpdated: new Date(learnerAssessment?.lastUpdatedDate) || new Date(),
          },
        });
      }
    } else {
      notifyError(assessment?.message || "Error creating assessment");
    }
  }, [createAssessmentData]);

  const handleSecondaryButtonClick = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const validateObject = async () => {
      await getLearnerAssessment.refetch();
    };

    if (selected) {
      validateObject();
    }
  }, [selected]);

  const goToReport = () => {
    setDialogOpen(false);
    go(`/assessment-reports-v2/${learnerAssessmentData?.getReports?.edges[0]?.node?.id}`, {
      state: {
        id: learnerAssessmentData?.getReports?.edges[0]?.node?.id,
        clientName: selected?.primary,
        lastUpdated: new Date(learnerAssessmentData?.getReports?.edges[0]?.node?.lastUpdatedDate),
      } as AssessmentReport,
    });
  };

  const primaryButtonDisableStatus = () => {
    if ((selected && learnerAssessmentData?.getReports?.edges[0]?.node) || isPending) return true;
    else return false;
  };

  return (
    <Dialog
      title="New Assessment Report"
      open={isDialogOpen}
      setOpen={setDialogOpen}
      primaryButton={{
        text: isPending ? "Creating..." : "Yes, Continue",
        onClick: createAssessmentObject,
        disabled: primaryButtonDisableStatus(), // @alex this not working not sure why if you have any idea what is wrong here
      }}
      secondaryButton={{ text: "No, Cancel", onClick: handleSecondaryButtonClick }}
      size="md"
    >
      {isSelfServeUser && <SelfServeText credits={credits} />}
      {!isSelfServeUser && (
        <Select
          title="Select Client"
          isLoading={isLoading}
          items={itemsData}
          placeholderText=""
          selected={selected}
          setSelected={setSelected}
          actionItem={{
            label: "Create New",
            onClick: () => createAssessmentObject(),
          }}
        />
      )}
      {learnerAssessmentLoading && <Small>Loading...</Small>}
      {learnerAssessmentData?.getReports?.edges[0]?.node ? (
        <>
          <Small displayType="normal" className="-mt-6">
            An assessment for this client already exists.
            <br /> Click{" "}
            <span className="cursor-pointer text-secondary underline" onClick={() => goToReport()}>
              here
            </span>{" "}
            to view
          </Small>
        </>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

const SelfServeText = ({ credits }: { credits: number }) => {
  return (
    <div className="text-text-primary">
      <p>You are about to create a new assessment report.</p>
      <p className="font-semibold">This will use one (1) credit.</p>
      <p className="text-limestone-600 mb-4">You will have {credits - 1} credits remaining.</p>
      <p>Would you like to proceed?</p>
    </div>
  );
};
