import {
  ArrowDownIcon,
  ArrowUpIcon,
  DocumentDuplicateIcon,
  SparklesIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  ApiReportSectionsAiGeneratedStatusChoices,
  ReportSectionsType,
  useCreateReportSectionFieldMutation,
  useDeleteReportSectionMutation,
  useMoveReportSectionUpAndDownMutation,
  useStartSingleSectionWorkflowMutation,
  useUpdateReportSectionMutation,
} from "@api/graphql/types-and-hooks";
import { AddContentMenu } from "@components/SectionWidget/AddContentMenuV2";
import {
  ContentTypeAndBespokeType,
  SectionWidget,
  SupportedContentType,
} from "@components/SectionWidget/SectionWidgetV2";
import { notifyError } from "@components/notifications/notifications";
import { FieldComponentWrapper } from "@pages/AssessmentReportV2Details/Components/FieldComponentWrapper";
import { getNewFieldRankOrder, getSortedFieldsByRankOrder } from "@pages/EvaluationDetails/Components/helper";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface SectionWidgetWrapperProps {
  sectionType?: "recommendation" | "regular";
  section: ReportSectionsType;
  supportedContentTypes: SupportedContentType[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  allowInLineEdit?: boolean;
}

export const SectionWidgetWrapper: React.FC<SectionWidgetWrapperProps> = ({
  sectionType = "regular",
  section,
  permissions,
  supportedContentTypes,
  allowInLineEdit = false,
}) => {
  const { evaluationId } = useParams();
  const { assessmentQuery } = useAssessmentBuilderData();
  const updateSection = useUpdateReportSectionMutation({});
  const deleteSection = useDeleteReportSectionMutation({});
  const addSectionField = useCreateReportSectionFieldMutation({});
  const moveReportSectionUpAndDown = useMoveReportSectionUpAndDownMutation({});
  const [isInLineEditMode, setIsInLineEditMode] = useState<boolean>(false);

  const startSingleSectionWorkflow = useStartSingleSectionWorkflowMutation({});

  const sectionStillGenerating =
    section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending;

  const sectionErrored = section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure;

  const onEditSection = async (newHeading: string) => {
    await updateSection
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        title: newHeading,
      })
      .then((data) => {
        if (data.updateReportSection?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating section title : ", error);
        notifyError("Error Updating Section Title");
      });
  };

  const onDeleteSection = async () => {
    await deleteSection
      .mutateAsync({
        reportId: evaluationId ?? "<missing-evaluation-id>",
        reportSectionId: section.id ?? "<missing-section-id>",
      })
      .then((data) => {
        if (data.deleteReportSection?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while deleting section : ", error);
        notifyError("Error Deleting Section");
      });
  };

  const onAddFieldComp = async (contentTypeAndBespokeType: ContentTypeAndBespokeType, newRankOrder: number) => {
    await addSectionField
      .mutateAsync({
        reportId: evaluationId ?? "<missing-evaluation-id>",
        reportSectionId: section.id ?? "<missing-section-id>",
        fieldData: {
          title: "Enter Title",
          rankOrder: newRankOrder ?? getNewFieldRankOrder(section),
          fieldType: contentTypeAndBespokeType.contentType,
          bespokeType: contentTypeAndBespokeType.bespokeType,
        },
      })
      .then((data) => {
        if (data?.createReportSectionField?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while adding section field : ", error);
        notifyError("Error Adding Section Field");
      });
  };

  const handleMoveSectionUpAndDown = async (moveUp: boolean) => {
    // moveUp = True to move up and False to move down
    await moveReportSectionUpAndDown
      .mutateAsync({
        reportId: evaluationId ?? "<missing-evaluation-id>",
        sectionId: section.id ?? "<missing-section-id>",
        moveUp: moveUp,
        pageLocation: section.pageLocation,
      })
      .then((data) => {
        if (data?.moveReportSectionUpAndDown?.status) {
          assessmentQuery?.refetch();
        } else if (data.moveReportSectionUpAndDown?.status === false) {
          notifyError(data.moveReportSectionUpAndDown?.message ?? "Error moving section in the specified direction");
        }
      })
      .catch((error) => {
        console.error("Error moving section : ", error);
        notifyError("Error Moving Section");
      });
  };

  const actionButtonMenu = [
    {
      label: "Move Up",
      Icon: ArrowUpIcon,
      callback: () => handleMoveSectionUpAndDown(true),
    },
    {
      label: "Move Down",
      Icon: ArrowDownIcon,
      callback: () => handleMoveSectionUpAndDown(false),
    },
    {
      label: "Duplicate",
      Icon: DocumentDuplicateIcon,
      callback: () => console.debug("implement duplicate"),
    },
    {
      label: "Delete",
      Icon: TrashIcon,
      textColorClassName: "text-red-600",
      callback: onDeleteSection,
    },
  ];

  // NOTE: sectionIdentifier only is populated for our AI fields to map them back to the original name
  // we use this to determine which sections are AI generate (and thus get the regenerate option)
  // and which are user-created
  if (section.sectionIdentifier) {
    actionButtonMenu.unshift({
      label: "Regenerate",
      Icon: SparklesIcon,
      callback: async () => {
        await startSingleSectionWorkflow.mutateAsync({ sectionId: section.id });
        await assessmentQuery.refetch();
      },
    });
  }

  const fields = getSortedFieldsByRankOrder(section);

  const isLoading = sectionStillGenerating || startSingleSectionWorkflow.isPending;

  return (
    <SectionWidget
      sectionType={sectionType}
      key={section.id}
      id={section.id}
      onAddContent={onAddFieldComp}
      onEditSection={onEditSection}
      permissions={permissions}
      sectionData={{ heading: section.title, icon: "document" }}
      supportedContentTypes={supportedContentTypes}
      placeholder="Enter section title"
      allowInLineEdit={allowInLineEdit}
      setIsInLineEditMode={setIsInLineEditMode}
      isInLineEditMode={isInLineEditMode}
      actionButtonMenu={actionButtonMenu}
      isLoading={isLoading}
      isError={sectionErrored}
      hasFields={fields.length > 0}
    >
      {fields.map((field) => (
        <div key={`${field?.id}-${isInLineEditMode}`}>
          <FieldComponentWrapper
            section={section}
            field={field ?? undefined}
            permissions={{
              canEdit: isInLineEditMode ? isInLineEditMode : permissions.canEdit,
              canDelete: isInLineEditMode ? isInLineEditMode : permissions.canDelete,
            }}
          />
          {/* Add Blocks */}
          {permissions.canEdit && !sectionStillGenerating ? (
            <AddContentMenu
              sectionId={section.id}
              sectionType={sectionType}
              onAddContent={onAddFieldComp}
              newRankOrder={field.rankOrder + 1}
              supportedContentTypes={supportedContentTypes}
            />
          ) : (
            <></>
          )}
        </div>
      ))}
    </SectionWidget>
  );
};
