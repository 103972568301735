import React, { useEffect, useState } from "react";

import { Button, TextArea } from "@fronterahealth/frontera-ui-components";

import { useUpdateMediaCommentMutation } from "@api/graphql/types-and-hooks";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface CommentEditFormProps {
  commentId: string;
  existingContent: string;
  handleCommentSubmit: () => void;
}
const CommentEditForm: React.FC<CommentEditFormProps> = ({ commentId, existingContent, handleCommentSubmit }) => {
  const { commentsByMediaIdQuery, setEditingCommentId } = useVideoSessionData();
  const [comment, setComment] = useState<string>("");

  const updateCommentMutation = useUpdateMediaCommentMutation();
  const { isPending } = updateCommentMutation;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (comment.trim()) {
      await updateCommentMutation
        .mutateAsync({
          input: {
            commentId,
            content: comment,
          },
        })
        .then((data) => {
          if (data.updateMediaComment?.status) {
            handleCommentSubmit();
            commentsByMediaIdQuery.refetch();
            setEditingCommentId(null);
          }
        });
    }
  };

  useEffect(() => {
    setComment(existingContent);
  }, [existingContent]);

  return (
    <form onSubmit={handleSubmit} className="relative w-full flex flex-col gap-2">
      <TextArea
        label=""
        name="comment"
        value={comment}
        onChange={handleChange}
        placeholder="Leave your comment..."
        required
        formNoValidate
        rows={2}
      />

      <div className="flex flex-row w-full justify-end gap-2 mt-1">
        <Button text="Cancel" appearance="text" onClick={() => setEditingCommentId(null)} />
        <Button type="submit" disabled={!comment.trim() || isPending} text="Save" appearance="primary" />
      </div>
    </form>
  );
};

export default CommentEditForm;
