import { Small } from "@fronterahealth/frontera-ui-components";

import { useCreateReportSectionsMutation } from "@api/graphql/types-and-hooks";
import { EvaluationSubRoute } from "@pages/EvaluationDetails/EvaluationDetails";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export type AddSectionSubRoute = Exclude<EvaluationSubRoute, "client-details" | "upload-files" | "review-and-sign">;

interface AddSectionProps {
  pageLocation: AddSectionSubRoute;
  defaultNewSectionTitle: string;
  newRankOrder: number;
  position?: "left" | "center" | "right";
}

export const AddSection: React.FC<AddSectionProps> = ({
  pageLocation,
  defaultNewSectionTitle,
  position = "center",
  newRankOrder,
}) => {
  const { assessmentQuery } = useAssessmentBuilderData();

  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node;
  if (!assessment) return null;

  const createSection = useCreateReportSectionsMutation({});
  const { isPending } = createSection;
  const onAddSection = async () => {
    await createSection
      .mutateAsync({
        reportId: assessment.id,
        runWorkflow: false,
        sections: [{ title: defaultNewSectionTitle, pageLocation: pageLocation, rankOrder: newRankOrder }],
      })
      .then((data) => {
        if (data.createReportSections?.status) {
          assessmentQuery?.refetch();
        }
      });
  };

  const alignmentClass = position === "center" ? "text-center" : position === "right" ? "text-right" : "text-left";

  return (
    <div className="px-4 -my-3">
      <div className="flex items-center w-full group" onClick={onAddSection}>
        <div
          className={`transition-all my-3 group-hover:border-limestone-200 border-b border-limestone-300 ${position === "left" ? "w-3" : "flex-grow"} `}
        />
        <div className={`px-2 ${alignmentClass} group-hover:block hidden`}>
          <Small displayType="normal" className={`cursor-pointer group-hover:text-text-primary transition-all `}>
            {isPending ? "Creating..." : "Add Section"}
          </Small>
        </div>
        <div
          className={`transition-all my-3 group-hover:border-limestone-200 border-b border-limestone-300 ${position === "right" ? "w-3" : "flex-grow"}`}
        ></div>
      </div>
    </div>
  );
};
