import { useAuth0 } from "@auth0/auth0-react";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  LongTermGoalBankTypeEdge,
  MoveGoalBankShortTermGoalInput,
  useGetUserOrgnizationDetailsQuery,
  useGoalCategoriesQuery,
  useGoalCategoryConfigQuery,
  useMoveTargetToFolderMutation,
} from "@api/graphql/types-and-hooks";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { useGetShortTermGoalsByLongTermGoalBankId } from "@components/GoalsBank/hooks/useGetShortTermGoals";
import { findFolderSelection } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface MoveTargetToFolderProps {
  currentFolderId: string;
  goalBankTargetId?: string;
  onCloseClick: () => void;
  open: boolean;
  foldersSelection: SelectItem[];
  refetch: () => void;
}

export const MoveTargetGoalToFolder: React.FC<MoveTargetToFolderProps> = ({
  currentFolderId,
  goalBankTargetId,
  onCloseClick,
  open,
  foldersSelection,
  refetch,
}) => {
  const { user } = useAuth0();
  const goalCategoryConfig = useGoalCategoryConfigQuery({ organizationId: user?.org_id }, { enabled: !!user?.org_id });
  const orgnizationDetailsQuery = useGetUserOrgnizationDetailsQuery(
    {},
    {
      queryKey: ["get-account-details"],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
    },
  );
  const { data: organizationDetailsData } = orgnizationDetailsQuery;
  const goalCategoryName = organizationDetailsData?.getUserOrgnizationDetails?.useLongTermGoals
    ? "Long Term Goal"
    : goalCategoryConfig.data?.goalCategoryConfig?.edges.at(0)?.node?.categoryConfigType ?? "";

  const moveTarget = useMoveTargetToFolderMutation({});
  const { formState, RegisteredFormSelected, reset, onSubmit, watch } = useFormUtils<MoveGoalBankShortTermGoalInput>({
    mutationFn: async (params) => {
      if (params.newFolderId && goalBankTargetId && params.goalBankLongTermId)
        moveTarget.mutateAsync(
          {
            input: {
              goalBankTargetId,
              goalBankShortTermId: params.goalBankShortTermId,
              goalBankLongTermId: params.goalBankLongTermId,
              currentFolderId,
              newFolderId: params.newFolderId,
            },
          },
          {
            onSuccess: async () => {
              await refetch();
              notifySuccess(`Successfully Moved Target`);
              reset();
              onCloseClick();
            },
            onError: async (error: unknown) => {
              console.error(`Error when moving target `, error);
            },
          },
        );
    },
  });
  const newFolderIdWatch = watch("newFolderId");
  const { longTermGoals } = useGetLongTermGoals(newFolderIdWatch ?? currentFolderId);
  const useLongTermGoals = organizationDetailsData?.getUserOrgnizationDetails?.useLongTermGoals;
  const { data: categoryData } = useGoalCategoriesQuery({ organizationId: user?.org_id }, { enabled: !!user?.org_id });

  const longTermGoalBankIdWatch = watch("goalBankLongTermId");
  const { shortTermGoals } = useGetShortTermGoalsByLongTermGoalBankId(
    newFolderIdWatch ?? currentFolderId,
    longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges.at(0)?.node?.id,
  );
  const placeholderText = () => {
    const selection = findFolderSelection(newFolderIdWatch ?? currentFolderId, foldersSelection);
    return selection ? selection.primary : "Select a folder";
  };
  const longTermGoalBankPlaceholder = () => {
    const selection = longTermGoals?.getGoalBankLongTermGoals?.edges.filter(
      (edge) =>
        edge?.node?.id === (longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges.at(0)?.node?.id),
    ) as LongTermGoalBankTypeEdge[];
    return selection?.at(0)?.node?.goalName ?? `Select ${goalCategoryName}`;
  };
  const longTermGoalsSelectItems = longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
    return {
      id: edge?.node?.id,
      primary: edge?.node?.goalName,
      secondary: convertReadableString(edge?.node?.goalType),
    };
  }) as SelectItem[];

  const goalCategories = categoryData?.goalCategories?.edges.map((categoryEdge) => {
    return {
      id: longTermGoals?.getGoalBankLongTermGoals?.edges
        .filter((edge) => edge?.node?.goalName.trim() === categoryEdge?.node?.name?.trim())
        .at(0)?.node?.id,
      primary: categoryEdge?.node?.name,
      secondary: "",
    };
  }) as SelectItem[];

  return (
    <FolderManagementDialog
      action={FolderManagementAction.MOVE}
      open={open}
      onCancelClick={onCloseClick}
      onSaveClick={onSubmit}
    >
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormSelected
          formKey="newFolderId"
          required
          formState={formState}
          items={foldersSelection}
          title={"Select Folder"}
          placeholderText={placeholderText()}
        />
        <RegisteredFormSelected
          required={true}
          formKey="goalBankLongTermId"
          formState={formState}
          items={useLongTermGoals ? longTermGoalsSelectItems : goalCategories}
          title={goalCategoryName}
          placeholderText={longTermGoalBankPlaceholder()}
        />
        <RegisteredFormSelected
          required={true}
          formKey="goalBankShortTermId"
          formState={formState}
          items={
            (shortTermGoals?.getGoalBankShortTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
              };
            }) as SelectItem[]) || []
          }
          title="Short Term Goal"
          placeholderText={"Select Short Term Goal"}
        />
      </FormContainer>
    </FolderManagementDialog>
  );
};
