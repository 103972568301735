import React from "react";

import { Small } from "@fronterahealth/frontera-ui-components";

interface DataLoadingProps {
  text: string;
}

export const DataLoading: React.FC<DataLoadingProps> = ({ text }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="p-1 rounded-full bg-limestone-400 w-10 h-10 bg-opacity-20 hover:bg-opacity-30 transition-all">
        <div className="animate-spin rounded-full h-8 w-8 border-b border-limestone-900" />
      </div>
      <Small>{text}</Small>
    </div>
  );
};
