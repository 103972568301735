import { nonNullable } from "@utils/utils";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Button, Paragraph, SkeletonLoader, TrainStopProgressNav } from "@fronterahealth/frontera-ui-components";

import {
  ApiReportReportStatusChoices,
  AssessmentToolType,
  ReportStatusEnums,
  ReportType,
  ReportTypeEnums,
  useAssessmentToolsQuery,
  useUpdateReportMutation,
} from "@api/graphql/types-and-hooks";
import { assessmentTrainTopStatusFromCurrentRoute } from "@pages/AssessmentReportV2Details/helpers";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export type AssessmentReportSubRoute =
  | "client-details"
  | "upload-files"
  | "report-part-1"
  | "prioritize"
  | "long-term-goals"
  | "short-term-goals"
  | "report-part-2"
  | "review-and-sign";

export const routeLabelMapping: { [key in AssessmentReportSubRoute]: string } = {
  "client-details": "Details",
  "upload-files": "Upload Files",
  "report-part-1": "Report Part 1",
  prioritize: "Prioritize",
  "long-term-goals": "Long Term Goals",
  "short-term-goals": "Short Term Goals",
  "report-part-2": "Report Part 2",
  "review-and-sign": "Review",
};

export const orderedAssessmentSubRoutes: AssessmentReportSubRoute[] = [
  "client-details",
  "upload-files",
  "report-part-1",
  "prioritize",
  "long-term-goals",
  "short-term-goals",
  "report-part-2",
  "review-and-sign",
];

export const baseRoutes: AssessmentReportSubRoute[] = ["client-details", "upload-files", "report-part-1", "prioritize"];

export const finalRoutes: AssessmentReportSubRoute[] = ["report-part-2", "review-and-sign"];

export const useOrderedAssessmentReportSubRoutes = () => {
  // TODO: BACKPORT: Need to port over `useLongTermGoals` DB column over to report builder
  // const { assessmentQuery } = useAssessmentBuilderData();
  // const assessmentReport = assessmentQuery.data?.getReports?.edges?.[0]?.node;
  // const goalRoutes: AssessmentReportSubRoute[] = assessmentReport.useLongTermGoals
  //   ? ["long-term-goals", "short-term-goals"]
  //   : ["short-term-goals"];

  const goalRoutes: AssessmentReportSubRoute[] = true ? ["long-term-goals", "short-term-goals"] : ["short-term-goals"];

  const orderedRoutes = [...baseRoutes, ...goalRoutes, ...finalRoutes] as AssessmentReportSubRoute[];

  return {
    orderedRoutes,
    getNextRoute: (route: AssessmentReportSubRoute) => {
      const currentIndex = orderedRoutes.indexOf(route);
      if (route === "review-and-sign") return "review-and-sign";
      return currentIndex >= 0 && currentIndex < orderedRoutes.length - 1 ? orderedRoutes[currentIndex + 1] : null;
    },
    getPastRoute: (route: AssessmentReportSubRoute) => {
      const currentIndex = orderedRoutes.indexOf(route);
      return currentIndex > 0 ? orderedRoutes[currentIndex - 1] : "client-details";
    },
    statusFromCurrentRoute: (currentRoute: AssessmentReportSubRoute, navStepRoute: AssessmentReportSubRoute) => {
      return currentRoute === navStepRoute
        ? "current"
        : orderedRoutes.indexOf(currentRoute) > orderedRoutes.indexOf(navStepRoute)
          ? "complete"
          : "upcoming";
    },
  };
};

export const AssessmentReportV2Details: React.FC = () => {
  const { pathname, state } = useLocation();
  const { assessmentQuery } = useAssessmentBuilderData();

  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.InitialAssessment });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];

  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node as ReportType;
  const files = assessment.reportFiles.edges?.map((edge) => edge?.node);
  if (!assessment) return null;
  const filesForAssessment = files?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);
  const existingAssessmentsFound =
    filesForAssessment?.filter((file) => supportedAssessmentsList.map((a) => a.id).includes(file.fileKind)).length >= 1;

  const updateAssessment = useUpdateReportMutation({});

  const { isPending } = updateAssessment;

  const updateAssessmentStatus = async () => {
    await updateAssessment
      .mutateAsync({
        reportId: assessment.id,
        status: ReportStatusEnums.Draft,
      })
      .then((data) => {
        if (data.updateReport?.status) {
          assessmentQuery?.refetch();
        }
      });
  };

  if (!assessment) return null;

  const reportIsCompleted = assessment.reportStatus === ApiReportReportStatusChoices.Completed;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);

  const clientName = `${assessment?.reportClientDetails?.clientFirstName || "N/A"} ${assessment?.reportClientDetails?.clientLastName || ""}`;

  return (
    <div className="-mt-12 flex w-full flex-col items-start justify-between">
      <div className="d-flex flex-col sticky top-4 pt-3  w-full z-20">
        <div className="-mt-7 flex flex-col gap-y-2 bg-white -ml-6 w-[calc(100%+48px)] p-4 ">
          <div className="flex w-full items-center justify-between px-2">
            <SkeletonLoader loading={!assessment}>
              <Paragraph colorType="primary" displayType="loud">
                {clientName} Assessment Report{" "}
              </Paragraph>
            </SkeletonLoader>
            {reportIsCompleted && (
              <div>
                <Button
                  text="Edit"
                  disabled={isPending}
                  appearance="secondary"
                  onClick={() => updateAssessmentStatus()}
                  className="mr-2 ring-green-400"
                />
                <Button
                  text="Export"
                  appearance="primary"
                  onClick={() => {
                    if (assessment.reportFileSignedUrl) {
                      window.open(assessment.reportFileSignedUrl);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {!reportIsCompleted ? (
          <div className="-ml-8 w-[calc(100%+64px)] bg-background-secondary">
            <TrainStopProgressNav
              steps={orderedAssessmentSubRoutes.map((route, index) => {
                const status = assessmentTrainTopStatusFromCurrentRoute(
                  currentRoute,
                  route,
                  assessment,
                  existingAssessmentsFound,
                );
                return {
                  index: index + 1,
                  name: routeLabelMapping[route],
                  status,
                  to: route,
                  navigationToEnabled: status === "upcoming" ? true : true,
                  Component: ({ children, className, to }) => (
                    <Link to={to} className={className} state={state}>
                      {children}
                    </Link>
                  ),
                };
              })}
            />
          </div>
        ) : null}
      </div>
      <div className="w-full mt-4 py-8 h-full">
        <Outlet />
      </div>
    </div>
  );
};
