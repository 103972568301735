import { Outlet, useLocation } from "react-router-dom";

import { Heading } from "@fronterahealth/frontera-ui-components";

import { FilterBar } from "@pages/VideoSessions/components/FilterBar";
import { MediaCards } from "@pages/VideoSessions/components/MediaCards";

export const VideoSessionsSummary: React.FC = () => {
  const location = useLocation();
  const isDetailView = location.pathname.includes("/video-sessions/") && location.pathname !== "/video-sessions";

  if (isDetailView) {
    return <Outlet />;
  }

  return (
    <div className="h-full w-full flex flex-col gap-4 lg:px-8 px-4 lg:px-6">
      <Heading type="h1" className="text-xl md:text-4xl">
        Video Sessions
      </Heading>
      <FilterBar />
      <MediaCards />
    </div>
  );
};
