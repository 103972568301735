import { StarIcon } from "@heroicons/react/24/outline";

interface SessionRatingProps {
  rating: number;
}

export const SessionRating: React.FC<SessionRatingProps> = ({ rating }) => {
  return (
    <div className="flex justify-center items-center px-2 py-1 w-28 h-6 border border-gray-300 rounded-2xl">
      <div className="flex items-center gap-1">
        {[...Array(5)].map((_, i) => (
          <StarIcon key={i} height={16} fill={i < rating ? "grey" : "none"} color="grey" />
        ))}
      </div>
    </div>
  );
};
