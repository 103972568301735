import {
  ArchiveBoxIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  ChatBubbleBottomCenterIcon,
  CheckBadgeIcon,
  FolderIcon,
  LifebuoyIcon,
  MinusCircleIcon,
  PhoneIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { HeroIconType } from "@utils/utils";

import { AssessmentReportFieldsEnums } from "@api/graphql/types-and-hooks";

interface SectionConfig {
  icon: HeroIconType;
  fields: AssessmentReportFieldsEnums[];
}

const sectionConfigMap = new Map<string, SectionConfig>([
  [
    "Behavior Reduction Goals",
    {
      icon: ArrowTrendingDownIcon,
      fields: [AssessmentReportFieldsEnums.BehaviorReductionSummary],
    },
  ],
  [
    "Skill Acquisition Goals",
    {
      icon: ArrowTrendingUpIcon,
      fields: [AssessmentReportFieldsEnums.SkillAcquisitionSummary],
    },
  ],
  [
    "Parent / Caregiver Goals",
    {
      icon: UsersIcon,
      fields: [AssessmentReportFieldsEnums.ParentCaregiverSummary],
    },
  ],
  [
    "Coordination of Care",
    {
      icon: ChatBubbleBottomCenterIcon,
      fields: [AssessmentReportFieldsEnums.CoordinationOfCare],
    },
  ],
  [
    "Program Recommendations",
    {
      icon: CheckBadgeIcon,
      fields: [AssessmentReportFieldsEnums.ProgramRecommendations],
    },
  ],
  [
    "Service Reduction and Termination",
    {
      icon: MinusCircleIcon,
      fields: [
        AssessmentReportFieldsEnums.TitrationPlan,
        AssessmentReportFieldsEnums.DischargePlan,
        AssessmentReportFieldsEnums.MedicalNecessity,
      ],
    },
  ],
  [
    "Risk Evaluation & Emergency Plan",
    {
      icon: LifebuoyIcon,
      fields: [AssessmentReportFieldsEnums.RiskEvaluation, AssessmentReportFieldsEnums.EmergencyPlan],
    },
  ],
  [
    "Program Contacts for Clients",
    {
      icon: PhoneIcon,
      fields: [AssessmentReportFieldsEnums.ProgramContactsForClient],
    },
  ],
  [
    "References",
    {
      icon: ArchiveBoxIcon,
      fields: [AssessmentReportFieldsEnums.References],
    },
  ],
]);

export const getIconForSection = (section: string): HeroIconType => {
  return sectionConfigMap.get(section)?.icon || FolderIcon;
};

export const getFieldsForSection = (section: string): AssessmentReportFieldsEnums[] => {
  return sectionConfigMap.get(section)?.fields || [];
};

export const isLongTermGoalTypeSection = (section: string): boolean => {
  return ["Behavior Reduction Goals", "Skill Acquisition Goals", "Parent / Caregiver Goals"].includes(section);
};

export const getAllSections = (): string[] => {
  return Array.from(sectionConfigMap.keys());
};

export const fieldEnumToHumanLabelMapping: { [key in AssessmentReportFieldsEnums]: string } = {
  [AssessmentReportFieldsEnums.DevelopmentalDisabilityDiagnosis]: "Developmental Disability Diagnosis",
  [AssessmentReportFieldsEnums.DateOfDiagnosis]: "Date of Diagnosis",
  [AssessmentReportFieldsEnums.DateAndTimeOfAssessment]: "Date and Time of Assessment",
  [AssessmentReportFieldsEnums.DateOfReportWriteUp]: "Date of Report Write-up",
  [AssessmentReportFieldsEnums.InsuranceName]: "Insurance Name",
  [AssessmentReportFieldsEnums.MemberId]: "Member ID",
  [AssessmentReportFieldsEnums.LocationOfServices]: "Location of Services",
  [AssessmentReportFieldsEnums.BcbaCompletingAssessmentAndReportWriteUp]:
    "BCBA completing Assessment and Report Write-up",
  [AssessmentReportFieldsEnums.StatementOfConfidentiality]: "Statement of Confidentiality",
  [AssessmentReportFieldsEnums.DiagnosticInformation]: "Diagnostic Information",
  [AssessmentReportFieldsEnums.ReasonForReferral]: "Reason for referral",
  [AssessmentReportFieldsEnums.DevelopmentalHistory]: "Developmental History",
  [AssessmentReportFieldsEnums.FamilyHistoryOrInformation]: "Family History / Information",
  [AssessmentReportFieldsEnums.OtherMedicalDiagnosesOfClient]: "Other medical diagnoses of client",
  [AssessmentReportFieldsEnums.MedicationPrescribed]: "Medication Prescribed",
  [AssessmentReportFieldsEnums.AllergiesOrDietaryRestrictions]: "Allergies or dietary restrictions",
  [AssessmentReportFieldsEnums.FamilyHistoryOfDiagnosis]: "Family history of diagnosis: (Maternal/Paternal/Siblings)",
  [AssessmentReportFieldsEnums.OtherHealthConcernsOfClient]: "Other health concerns of client",
  [AssessmentReportFieldsEnums.NameOfSchool]: "Name of School",
  [AssessmentReportFieldsEnums.GradeAndEducationalSetting]: "Grade and educational setting",
  [AssessmentReportFieldsEnums.IepOrBipInSchool]: "IEP/BIP in school",
  [AssessmentReportFieldsEnums.TherapeuticServicesInSchoolAndHours]: "Therapeutic services in school and hours",
  [AssessmentReportFieldsEnums.TherapyServiceHoursNameOfProviderDates]:
    "Chart: Therapy/Service, Hours of Services Provided, Name of Service Provider, Dates of Services Provided",
  [AssessmentReportFieldsEnums.AssessmentsOrInformationUsed]: "Assessments/Information Used",
  [AssessmentReportFieldsEnums.AssessmentWriteUp]: "Assessment Write Up",
  [AssessmentReportFieldsEnums.ClinicalObservationDuringAssessment]: "Clinical Observation During Assessment",
  [AssessmentReportFieldsEnums.LearnerStrengths]: "Learner Strengths",
  [AssessmentReportFieldsEnums.PreferredItems]: "Preferred Items",
  [AssessmentReportFieldsEnums.KnownAversions]: "Known aversions",
  [AssessmentReportFieldsEnums.QabfOrNarrativeFunctionalAssessment]: "QABF/Narrative functional assessment",
  [AssessmentReportFieldsEnums.AssessmentSummary]: "Assessment Summary",
  [AssessmentReportFieldsEnums.BehaviorInterventionPlanBip]: "Behavior Intervention Plan (BIP)",
  [AssessmentReportFieldsEnums.CoordinationOfCare]: "Coordination of Care", // Added based on pattern
  [AssessmentReportFieldsEnums.ProgramRecommendations]: "Treatment Recommendations/Services Requests",
  [AssessmentReportFieldsEnums.MedicalNecessity]: "Medical Necessity", // Added based on pattern
  [AssessmentReportFieldsEnums.TitrationPlan]: "Titration Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.DischargePlan]: "Discharge Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.RiskEvaluation]: "Risk Evaluation", // Added
  [AssessmentReportFieldsEnums.EmergencyPlan]: "Emergency Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.ProgramContactsForClient]: "Program Contacts For Client", // Added based on pattern
  [AssessmentReportFieldsEnums.References]: "References", // Added based on pattern
  [AssessmentReportFieldsEnums.AbaBackgroundInSchool]: "ABA Background In School", // Added based on pattern
  [AssessmentReportFieldsEnums.BehaviorReductionSummary]: "Summary",
  [AssessmentReportFieldsEnums.ReplacementBehaviorSummary]: "Summary",
  [AssessmentReportFieldsEnums.SkillAcquisitionSummary]: "Summary",
  [AssessmentReportFieldsEnums.ParentCaregiverSummary]: "Summary",
  [AssessmentReportFieldsEnums.ReportSignature]: "Signature",
};
